import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CuentaComponent } from '../../components/inscripcion-cuenta/cuenta/cuenta.component';
import { RegistrarCuentaComponent } from '../../components/inscripcion-cuenta/registrar-cuenta/registrar-cuenta.component';

@Component({
  selector: 'app-inscripcion-cuenta-banco',
  standalone: true,
  imports: [CommonModule, HeaderComponent, MatIconModule, MatButtonModule, CuentaComponent, RegistrarCuentaComponent],
  templateUrl: './inscripcion-cuenta-banco.component.html',
  styleUrl: './inscripcion-cuenta-banco.component.css'
})
export class InscripcionCuentaBancoComponent {

  frmRegistrarVisible = false;

  @ViewChild(CuentaComponent) cardCuenta?: CuentaComponent;

  hiddeRegistrar(id: number){
    this.frmRegistrarVisible = false;

    /**SI SE CREARON REGISTROS VOLVEMOS A CONSULTAR LAS CUENTAS*/
    if( id ){
      this.cardCuenta?.getCuentasBanco();
    }

  }

}
