import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { InscripcionCuentaService } from '@app/modules/home/services/inscripcion-cuenta-banco/inscripcion-cuenta.service';
import { ICuentaBanco, IDeleteCuenta } from '@app/modules/home/models/inscripcion-cuenta-banco/inscripcion-cuenta-interface';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { UtilsService } from '@app/shared/services/utils.service';

@Component({
  selector: 'app-cuenta',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatCardModule, MatIconModule, LoadingComponent],
  templateUrl: './cuenta.component.html',
  styleUrl: './cuenta.component.css'
})
export class CuentaComponent implements OnInit {
  
  cuentas:any = [];
  loading = false;

  constructor(private cuentaBancoService: InscripcionCuentaService, private utilService: UtilsService){}

  ngOnInit(): void {
    this.getCuentasBanco();
  }


  deleteCuenta(id: string){
    this.loading = true;

    const payload = {
      "usubancue_codigo": id 
    }

    this.cuentaBancoService.deleteCuentaBanco(payload).subscribe((resp:IDeleteCuenta)=>{
      
      if(resp.state){
        this.getCuentasBanco();
        
      }else{
        this.loading = false;
        this.utilService.showAlert(resp.message);
      }

    },error=>{
      this.loading = false;
      console.log('Error',error);
    });

  }


  getCuentasBanco(){
    this.loading = true;
    this.cuentaBancoService.getCuentas().subscribe((resp:ICuentaBanco)=>{
      this.loading= false;
      this.cuentas = resp.response
    }, error =>{
      this.loading = false;
      console.log('Error',error);
    });
  }


}
