import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-wompi',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './wompi.component.html',
  styleUrl: './wompi.component.css'
})
export class WompiComponent {
  @Input() public_key = '';
  @Input() ref_pago = '';
  @Input() valor_centavos = 0;
  @Input() visible = false;
  @Input() redirect_url_wompi = '';
  @Input() signature:any = ''; 
}