import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';


@Component({
  selector: 'app-movimientos',
  standalone: true,
  imports: [CommonModule,MatCardModule],
  templateUrl: './movimientos.component.html',
  styleUrl: './movimientos.component.css'
})

export class MovimientosComponent {
 
  @Input() data:any = []; 

}

