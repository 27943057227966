@if( pantalla == "registrarse" ){
    <app-form-login
        title="Registrarse"
        [loading]="loading"
        [btnInicioSesion]="true"
        (onSubmit)="onSubmit($event)"
        (iniciarSesion)="iniciarSesion()"
    />
}@else if(pantalla == 'ingresarPin'){
    <app-form-pin
        title="Ingresar PIN"
        [pantalla]="pantalla"
        [btnIniciarSesion]="btnIniciarSesion"
        [loading]="loading"
        (registrarPin)="registrarPin($event)"
        (registrarse)="iniciarSesion()"
    />
    

}@else if(pantalla == 'registro'){
    <app-form-registro
        [data] = "data"
        [loading]="loading"
        (onRegistrarse)="onRegistrarse($event)"
    />
}


