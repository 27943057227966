<div class="m-div-continer">
    <app-header />

   
    <div class="m-div-btn">

        <button mat-stroked-button (click)="sacarPlata()">
            <img src="../../../../../assets/image/icon_retirar.png" alt="sacar-plata">
            <p>Sacar plata</p>
        </button>
       
        <button mat-stroked-button (click)="pagar()">
            <img src="../../../../../assets/image/icon_money_blue.png" alt="pagar">
            <p>Pagar</p>
        </button>
    
    </div>

    @if(loading){

        <div class="m-loading">
            <app-loading [tamano]="35" />
        </div>
    }@else {
            <app-movimientos 
                [data]="data"
            />
    }

</div>