<div class="m-div-reg-empresa-card">
    <mat-card>
        <mat-card-header>
            <h1>Registar Empresa</h1>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="formRegEmpresa" (submit)="handlerOnSubmit()">
                
                <section>
                    <mat-form-field>
                        <mat-label>Empresa:</mat-label>
                        <mat-select formControlName="cmbEmpresa">
                            @for (comercio of comercios; track comercio.comercio_codigo) {
                                <mat-option value="{{comercio.comercio_codigo}}">{{comercio.comercio_nombre}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </section>
             
                <mat-card-actions>
                    @if(loading){
                        <app-loading [tamano]= "25"/>
                    }@else{
                        <button mat-flat-button type="submit" [disabled]="formRegEmpresa.invalid" ><mat-icon>save</mat-icon></button>
                        <button mat-flat-button (click)="handlerClose()"><mat-icon>close</mat-icon></button>
                    }
                </mat-card-actions>

            </form>
        </mat-card-content>
    </mat-card>
</div>