import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router'

/**COMPONENTES DE ANGULAR MATERIAL */
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

/**COMPONENTS */
import { FormLoginComponent } from '@auth/components/form-login/form-login.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';

/**INTERFACE*/
import { IEmpresaUsuario, IResponseLogin, IResponseOTP, IUtilities, IValidarIdentificador } from '../../models/validaridentificador';

/**SERVICES*/
import { FirebaseService } from '@shared/services/firebase.service';
import { LoginService } from '@auth/services/login.service';
import { UtilsService } from '@shared/services/utils.service';
import { FormPinComponent } from '@auth/components/form-pin/form-pin.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { RegistrarEmpresaComponent } from '../../components/registrar-empresa/registrar-empresa.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, LoadingComponent,FormLoginComponent, FormPinComponent,MatInputModule, MatSelectModule,MatFormFieldModule, MatButtonModule, MatIconModule, RegistrarEmpresaComponent ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  loading = false;
  loginForm!: FormGroup ;

  frmRegEmpresaVisible = false;

  isLogged: boolean = false;
    
  pantalla = 'login';
  tipo_validacion = 'LOGIN';
  indicativo?:string;
  celular?:string;

  userResponse: any = {};

  identificador?:string; 

  empresaComercio:any = [];
  empresas:any = [];

  //pantalla pin
  btnRecordar = false;
  btnRegistrarse = false;
  btnIniciarSesion = false;
  btnEmpresa = true;

  constructor(private loginService: LoginService, private router: Router, private utilService: UtilsService, private firebaseService: FirebaseService ) { }
  
  ngOnInit(): void {
    this.validarIslogin();
    this.loginService.pantalla$.subscribe(value => {
      this.pantalla = value;
    });

    this.empresas = this.loginService.getUserLocalStorage('user-empresas');
    this.userResponse = this.loginService.getUserLocalStorage('user-billetera');
  }

  /**VALIDAR IDENTIFICADOR */
  onSubmit(event:FormGroup) {

    this.loginForm = event;

    if( this.validarIslogin() ){
      return;
    }
   
    this.loading = true;
    this.identificador  = `${this.loginForm.value['indicativo']}${this.loginForm.value['celular']}`; 
    this.indicativo     = this.loginForm.value['indicativo'];
    this.celular        = this.loginForm.value['celular'];

    let payload = {
      usuide_identificador: this.identificador, 
      tipo_validacion: this.tipo_validacion
    }

    this.loginService.validarIdentificador(payload).subscribe((resp: IValidarIdentificador)=>{
      
      this.loading = false;  
     
      if (!resp.state){
        this.utilService.showAlert(resp.message);
      }else{

        if(resp.response.validar_otp){
          this.utilService.showAlert(resp.message,'Exito','success');
          this.loginService.setPantalla('ingresarPin');
          //this.pantalla       = 'ingresarPin';
          this.btnRegistrarse = true;
        }else{
          this.loginService.setPantalla('ingresarClave');
          //this.pantalla = 'ingresarClave';
        }  

      }

    }, (err:HttpErrorResponse) =>{
        this.utilService.showAlert(JSON.stringify(err.message));
        console.log('Error',err);
        this.loading = false;  
    });

  }

 
  registrarPin(event: any){
    
    this.loading = true;

    let payload = {
      usuide_identificador: this.identificador, 
      otpideval_otp: event,
      tipo_validacion: "LOGIN"
    }

    
    this.loginService.validarOtp(payload).subscribe((resp:IResponseOTP)=>{
      
      this.loading = false;

      if(!resp.state){
        this.utilService.showAlert(resp.message);
      }else{
        this.loginService.setPantalla('ingresarClave');
        //this.pantalla       = 'ingresarClave';
        this.btnRegistrarse = true;
      }

    }, (err:HttpErrorResponse)=>{
      this.utilService.showAlert(err.message);
      console.log('Error',err);
      this.loading = false;
    });
  }

  ingresarClave(event: any){
    
    this.loading = true;
    let token = this.firebaseService.getToken();
    if(token.length === 0){
      this.utilService.showAlert("Le recomendamos activar las notificaciones y volver a iniciar sesión para estar enterado de todo lo que sucede en tu cuenta.", "Información", "info");
      this.loading = false;
      //return; Permitir continuar
    }
    let payload = {
      usuide_identificador: this.identificador, 
      tipo_validacion: 'LOGIN',
      usuario_clave: event,
      usuario_app_token: token,
      validar_otp: 'NO'
    }
    
    
    this.loginService.login(payload).subscribe((resp:IResponseLogin)=>{
      
      this.loading = false;

      if( !resp.state ){
        this.utilService.showAlert(resp.message);
      }else{

        this.userResponse = resp.response;
        this.userResponse.celular = (this.celular)?.toString();

        let data={
          usuide_identificador: resp.response.usuide_identificador, 
          token: resp.response.token
        }

        this.loginService.getEmpresaUsuario(data).subscribe((resp: IEmpresaUsuario)=>{
          
          if( !resp.state ){
            this.utilService.showAlert(resp.message);
          }else{
            this.empresas = resp.response;
            this.loginService.setPantalla('ingresarEmpresa');
            //this.pantalla = "ingresarEmpresa";
          }
     
        }, (err:HttpErrorResponse)=>{
          this.utilService.showAlert(err.message);
        });
       
      }

    }, (err:HttpErrorResponse)=>{
      this.utilService.showAlert(err.message);
      console.log('Error',err);
      this.loading = false;
    });

  }

  onSelecionarEmpresa(){

    if( this.empresaComercio.length == 0 ){
      this.btnEmpresa = true;
      this.utilService.showAlert('Debe selecionar una empresa', 'Advertencia', 'warning');
      return;
    }
    this.loginService.setUserLocalStorage("user-billetera", this.userResponse);
    this.loginService.setUserLocalStorage("user-empresa", this.empresaComercio);
    this.loginService.setUserLocalStorage("user-empresas", this.empresas);
    
    this.loginService.getUtilidades().subscribe((resp:IUtilities ) =>{
      localStorage.setItem("utils", JSON.stringify(resp));
    });
    this.loginService.setPantalla('login');
    this.router.navigate(['/home']);
  }

  onChangeEmpresa(event: any){

    if(event.value){
      this.empresaComercio = [{
                                comercio_codigo: event.value,
                                comercio_nombre: event.source.triggerValue
                             }];

      this.validarCuentaBilletera(event.value);                       
      

    }else{
      this.btnEmpresa = true;
      this.empresaComercio = [];
      this.utilService.showAlert('Debe selecionar una empresa', 'Advertencia', 'warning');
    }

  }

  
  recordar(){
    
    if( this.validarIslogin() ){
      return;
    } 
    this.router.navigate(['/recordar']);
  }
  
  registrarse(){

    if( this.validarIslogin() ){
      return;
    }
    
    this.router.navigate(['/registrarse']);
  }

  validarIslogin(){

    this.isLogged = this.loginService.isLogged();

    if( this.isLogged ){
      this.router.navigate(['/inicio']);
    }

    return this.isLogged;
  }



  validarCuentaBilletera(empresa: any){
    
    const payload = {
      "tiecat_codigo": "1", //empresa de trasporte
      "comercio_codigo": empresa, 
      "tercero_identificacion": this.userResponse.usuario_identificacion, 
      "tercero_telefono":  this.userResponse.celular
    }

    console.log('payload',payload);
    this.loading = true;
    this.loginService.validarCuentaBilletera(payload).subscribe((resp:any)=>{
     
      
      if( !resp.state ){
        this.loading = false;
        this.utilService.showAlert(resp.message,'Error', 'error');
        return;
      }

      this.actalizarEmpresa(empresa);
    
    }, error=>{
      this.loading = false;
      console.log('error-validarcuenta-billetera',error);
      this.utilService.showAlert('Ocurrió un error al conectar con el servicio.','Error', 'error');
    });
  }


  closeFrmRegEmpresa(value: boolean){
    this.frmRegEmpresaVisible = value;
  }

  onRegistrarEmpresa(empresa: any){
    
    this.loading = true;

    const payload = {

      tercero_tipo_identificacion: this.userResponse.usuario_tipo_identificacion,
      tercero_identificacion: this.userResponse.usuario_identificacion,
      empresa_usuario: empresa[0]['comercio_codigo'],
      tertel_numero_identificador:this.userResponse.usuide_identificador

    };

    this.loginService.regitarEmpresaUsuario(payload).subscribe((resp:any)=>{
    
      this.loading = false;

      if(resp.state){
        this.utilService.showAlert(resp.message,'Exito!','success');
        this.empresas = [...this.empresas, empresa[0]];
        this.frmRegEmpresaVisible = false;
      }else{
        this.utilService.showAlert(resp.message,'Advertencia!','warning');
      } 


    }, (error:any)=>{
      this.loading = false;
      console.log('Error',error);
    });


  }

  //actualiza la billetera por la empresa que va a utilizar
  actalizarEmpresa(empresa:any){

    const payload = {

      tercero_tipo_identificacion: this.userResponse.usuario_tipo_identificacion,
      tercero_identificacion: this.userResponse.usuario_identificacion,
      comercio_codigo: empresa

    };

    this.loginService.updateBilleteraUsuario(payload).subscribe((resp:any)=>{
      this.loading = false;
      if(resp.state){
        this.btnEmpresa = false; //habilitar btn ingresar
      }else{
        this.utilService.showAlert(resp.message,'Advertencia!','warning');
      }

    }, error=>{
      this.loading = false;
      console.log('Error',error);
    });
  }
 
}