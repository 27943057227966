<app-header title="Extracto" />

<section>
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                <h2>Corte</h2>
            </mat-card-title>
        </mat-card-header>
        <form [formGroup]="formExtracto" (submit)="consultar()">
            <mat-card-content>
                <mat-form-field>
                    <mat-label>Selecciona el corte</mat-label>
                    <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="fechaCorte">
                    <mat-hint>YYYY-MM</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

            </mat-card-content>
            <mat-card-actions>
                @if(loading){

                <app-loading [tamano]="35" />

                }@else {

                <button mat-flat-button color="primary" type="submit"
                    [disabled]="formExtracto.invalid">Consultar</button>
                }
            </mat-card-actions>

        </form>

    </mat-card>
</section>

@if (data.length > 0) {

    <div class="d-pdf-container">

        <button mat-raised-button color="primary" (click)="generarPDF()">
            <mat-icon>picture_as_pdf</mat-icon> Generar
            pdf
        </button>


        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef>
                    Fecha
                </th>
                <td mat-cell *matCellDef="let element"> {{element.movimiento_fecha}} </td>
            </ng-container>

            <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef>
                    descripcion
                </th>
                <td mat-cell *matCellDef="let element"> {{element.movimiento_descripcion}} </td>
            </ng-container>

            <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef>
                    Valor
                </th>
                <td mat-cell *matCellDef="let element"> {{element.movimiento_valor}} </td>
            </ng-container>

            <ng-container matColumnDef="saldo">
                <th mat-header-cell *matHeaderCellDef>
                    Saldo
                </th>
                <td mat-cell *matCellDef="let element"> {{element.movimiento_saldo}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    
    </div>

}