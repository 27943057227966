import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Pqr } from '../../models/pqr/pqr-interface';

@Component({
  selector: 'app-pqr-mensajes',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatCardModule, MatButtonModule, MatIconModule],
  templateUrl: './pqr-mensajes.component.html',
  styleUrl: './pqr-mensajes.component.css'
})
export class PqrMensajesComponent implements OnInit {

  pqr: Pqr = {
    mensajes: null,
    pqr_codigo: null,
    pqr_estado: null,
    pqr_fecha_creacion: null,
    pqr_mensaje: null,
    pqr_ticket: null,
    pqr_tipo: null,
    total_mensajes: null,
    usuario_codigo: null
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.setData()
  }

  setData() {
    this.pqr = this.data;
    console.log(this.pqr);
  }

}
