
<div class="mat-div-container">

    @if( visibleComercios ){
        <mat-form-field>
            <mat-label>{{titleCmbComercio}}</mat-label>
            <mat-select [formControl]="cmbComercios" (selectionChange)="onChangeEmpHandler($event)">
                @for (comercio of comercios; track comercio) {
                <mat-option [value]="comercio.comercio_codigo">{{comercio.comercio_nombre}}</mat-option>
                }
            </mat-select>
            
            @if(  cmbComercios.hasError('required') && cmbComercios.dirty   ){
                <small>El campo es requerido</small>
            }

        </mat-form-field>
    }


    @if( visibleConceptos ){

        <mat-form-field>
            <mat-label>Concepto</mat-label>
            <mat-select [formControl]="cmbConceptos"  (selectionChange)="onChangeConceptoHandler($event)" value="">
                @for (concepto of conceptos; track concepto) {
                <mat-option [value]="concepto.comercio_codigo">{{concepto.comercio_nombre}}</mat-option>
                }
            </mat-select>

            @if(  cmbComercios.hasError('required') && cmbComercios.dirty   ){
                <small>El campo es requerido</small>
            }
        </mat-form-field>
    }

    @if( VisibleValor ){
        <mat-form-field>
            <mat-label>
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="attach_money"></mat-icon>
                Valor a retirar
            </mat-label>
            <input matInput type="text" [formControl]="txtValorCtrl" maxlength="10" (keyup)="keyUpHandler($event)">
            @if(  txtValorCtrl.hasError('required') && txtValorCtrl.dirty   ){
                <small>El campo es requerido</small>
            }

        </mat-form-field>
    }

    @if( !loading ){
        <div>
            <button mat-flat-button  color="primary" (click)="handleOnSubmit()"
                [disabled]="cmbComercios.invalid && cmbConceptos.invalid && txtValorCtrl.invalid"
            >Continuar</button>
        </div>
    }

</div>