<div class="container">

    <app-header />

    @if(loading){
    <div class="m-loading">
        <app-loading [tamano]="35" />
    </div>
    }@else {

    <div class="m-div-card">

        <mat-card>
            <mat-card-header>
                <div>
                    <mat-card-title>
                        <h1>Perfil usuario</h1>
                    </mat-card-title>
                </div>
                <div class="m-div-btn-edit">
                    <button mat-mini-fab color="primary" (click)="editar()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="formPerfil" (ngSubmit)="actualizar()">
                    <section>
                        <mat-form-field>
                            <mat-label>Tipo Identificación</mat-label>
                            <mat-select formControlName="tipoIdentificacion">
                                @for (tipo of tiposIdentificacion; track tipo) {
                                <mat-option value="{{tipo.tipdet_codigo}}">
                                    {{tipo.tipdet_nombre}}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                Número Identificación:
                            </mat-label>
                            <input matInput type="number" formControlName="numId">
                            @if (formPerfil.get('numId')?.invalid && formPerfil.get('numId')?.touched) {
                            <small>El número no es válido</small>
                            }
                        </mat-form-field>
                    </section>

                    <!-- NOMBRES -->
                    <section>
                        <mat-form-field>
                            <mat-label>
                                Primer Nombre:
                            </mat-label>
                            <input matInput type="text" formControlName="nombre1">

                            @if (formPerfil.get('nombre1')?.invalid && formPerfil.get('nombre1')?.touched) {
                            <small>El nombre no es válido</small>
                            }
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                Segundo Nombre:
                            </mat-label>
                            <input matInput type="text" formControlName="nombre2">
                        </mat-form-field>
                    </section>

                    <!-- APELLIDOS -->
                    <section>
                        <mat-form-field>
                            <mat-label>
                                Primer Apellido:
                            </mat-label>
                            <input matInput type="text" formControlName="ape1">

                            @if (formPerfil.get('ape1')?.invalid && formPerfil.get('ape1')?.touched) {
                            <small>El apellido no es válido</small>
                            }
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                Segundo Apellido:
                            </mat-label>
                            <input matInput type="text" formControlName="ape2">
                        </mat-form-field>
                    </section>

                    <!--EMPRESA Y CORREO -->
                    <section>

                        <mat-form-field>
                            <mat-label>Empresa</mat-label>
                            <mat-select formControlName="empresa">
                                @for (empresa of empresas; track empresa) {
                                <mat-option value="{{empresa.comercio_codigo}}">
                                    {{empresa.comercio_nombre}}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>
                                Correo Eletrónico:
                            </mat-label>
                            <input matInput type="email" formControlName="correo">

                            @if (formPerfil.get('correo')?.invalid && formPerfil.get('correo')?.touched) {
                            <small>El correo no es válido</small>
                            }
                        </mat-form-field>

                    </section>

                    <!-- DIRECCION Y BARRIO -->
                    <section>
                        <mat-form-field>
                            <mat-label>
                                Dirección:
                            </mat-label>
                            <input matInput type="text" formControlName="dir">

                            @if (formPerfil.get('dir')?.invalid && formPerfil.get('dir')?.touched) {
                            <small>El apellido no es válido</small>
                            }
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Barrio:</mat-label>
                            <input matInput type="text" formControlName="barrio">
                        </mat-form-field>
                    </section>

                    <!--CIUDAD Y GENERO -->
                    <section>

                        <mat-form-field>
                            <mat-label>Ciudad</mat-label>
                            <mat-select formControlName="ciudad">
                                @for (ciudad of ciudades; track ciudad.ciudad_codigo) {
                                <mat-option value="{{ciudad.ciudad_codigo}}">
                                    {{ciudad.ciudad_nombre}}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Género</mat-label>
                            <mat-select formControlName="genero">
                                @for (genero of generos; track genero.tipdet_codigo) {
                                <mat-option value="{{genero.tipdet_codigo}}">
                                    {{genero.tipdet_nombre}}
                                </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                    </section>

                    <section>
                        @if(edit){
                        <button mat-flat-button type="submit" class="m-btn-save" [disabled]="formPerfil.invalid">
                            <mat-icon>save</mat-icon>
                        </button>
                        }
                    </section>

                </form>

            </mat-card-content>

        </mat-card>

    </div>


    }

</div>