import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

/**INTERFACES */
import { EmpresasUsuario, FormaPago, IUtilities } from '@auth/models/validaridentificador';

/**COMPONENTES ANGULAR MATERIAL */
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule} from '@angular/material/checkbox';

/**COMPONENTES */
import { LoadingComponent } from '@shared/components/loading/loading.component';

/**SERVICES*/
import { LoginService } from '@auth/services/login.service';
import { UtilsService } from '@shared/services/utils.service';
import { FirebaseService } from '@app/shared/services/firebase.service';

@Component({
  selector: 'app-form-registro',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, 
    MatFormFieldModule, MatOptionModule, MatIconModule, LoadingComponent, MatSelectModule, MatInputModule, MatButtonModule, MatCheckboxModule
  ],
  templateUrl: './form-registro.component.html',
  styleUrl: './form-registro.component.css'
})
export class FormRegistroComponent implements OnInit {

  formRegistro: FormGroup;
  @Input() loading = false;
  tiposIdentificacion: FormaPago[]   = [];
  empresas: EmpresasUsuario[]        = [];
  
  identificador: any;
  tipo_validacion: any;
  indicativo: any;
  celular: any;
  
  @Input() data:any = {};
  @Output() onRegistrarse = new EventEmitter<any>();
  
  constructor(private fb: FormBuilder, private loginService: LoginService, private utilService: UtilsService, private firebaseService: FirebaseService ) {

    this.formRegistro = this.fb.group({
      tipoIdentificacion: ['',[Validators.required]],
      numId: ['', [Validators.required] ],
      nombre1: ['',[Validators.required]],
      nombre2: [''],
      ape1: ['',[Validators.required]],
      ape2: [''],
      empresa: ['',[Validators.required]],
      correo: ['',[Validators.required, this.utilService.validarEmail]],
      password: ['',[Validators.required, this.utilService.numeroLengthMinMax(4,4) ]],
      terminos: [false,[Validators.requiredTrue]],
    });

  }


  ngOnInit(): void {
    this.getParams();
    this.getUtils();
  }


  onSubmit(){

    let identificacion  = this.formRegistro.get('numId')?.value;
    let clave           = this.formRegistro.get('password')?.value;
    let token           = this.firebaseService.getToken();
 
    let payload = {
      tercero_tipo_identificacion: this.formRegistro.get('tipoIdentificacion')?.value,
      tercero_identificacion:     identificacion.toString(),
      tercero_primer_nombre:      this.formRegistro.get('nombre1')?.value,
      tercero_segundo_nombre:     this.formRegistro.get('nombre2')?.value,
      tercero_primer_apellido:    this.formRegistro.get('ape1')?.value,
      tercero_segundo_apellido:   this.formRegistro.get('ape2')?.value,
      usuario_clave:              clave.toString(),
      pais_codigo:                this.indicativo,
      tertel_numero:              this.identificador,
      tercor_correo:              this.formRegistro.get('correo')?.value,
      empresa_usuario:            this.formRegistro.get('empresa')?.value,
      usuide_identificador:       this.identificador,
      termino_codigo:             "1",
      tipo_genero:                "",
      tercero_fecha_expedicion:   "",
      tercero_fecha_nacimiento:   "",
      tercero_url_foto:           "",
      tercero_url_identificacion: "",
      tipcue_codigo:              "",
      ciudad_codigo:              "",
      terdir_barrio:              "",
      terdir_direccion:           "",
      tercero_tipo_persona:       "",
      usuario_app_token:          token
    } 
    
    this.onRegistrarse.emit(payload);

  }


  getUtils(){
    this.loginService.getUtilidades().subscribe((resp:IUtilities ) =>{
      this.tiposIdentificacion = resp.response.tipo_identificacion;
      this.empresas = resp.response.empresas_usuario;
    });
  }


  key(){
    let maxLength = 4;
    let value = this.formRegistro.get('password')?.value;

    if(!value){
      return;
    }
    
    if( value.toString().length > maxLength ){
      let newValue = value.toString().slice(0, maxLength);
      this.formRegistro.get('password')?.setValue(newValue); 
    }
  }

  getParams(){
   
    this.identificador    = this.data.identificador;
    this.tipo_validacion  = this.data.tipo_validacion;
    this.indicativo       = this.data.indicativo;
    this.celular          = this.data.celular;
  }

}
