import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { TransaccionService } from '../../services/transacciones/transaccion.service';
import { INotificaciones } from '../../models/transacciones/transaccion-interface';

@Component({
  selector: 'app-notificaciones',
  standalone: true,
  imports: [CommonModule, HeaderComponent, LoadingComponent, MatCardModule, MatFormFieldModule, MatButtonModule, MatIconModule],
  templateUrl: './notificaciones.component.html',
  styleUrl: './notificaciones.component.css'
})
export class NotificacionesComponent implements OnInit {

  loading = false;
  notificaciones: any = [];
  nroPage = 1;
  ultimaPagina = true;

  constructor(private transacionService: TransaccionService){}
  
  
  ngOnInit(): void {
    this.getNotificaciones();
  }

  getNotificaciones(){

    let pagina = this.nroPage;
    this.loading = true;
    this.ultimaPagina = true;

    this.transacionService.getNotificaciones(pagina).subscribe((resp:INotificaciones)=>{
      this.loading = false;
      
      if (resp.state){
        if( resp.response.datos ){
          this.ultimaPagina = resp.response.ultima_pagina;
          this.notificaciones = [...this.notificaciones, ...resp.response.datos];
        }
      }
    
    }, error=>{
      this.loading = false;
      console.log('error',error);
    });
  }


  nextPage(page: number){
    this.nroPage = this.nroPage + page;
    this.getNotificaciones();
  }

}
