import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgOtpInputModule } from  'ng-otp-input';


/**ANGULAR MATERIAL COMPONENTS */
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

@Component({
  selector: 'app-form-pin',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule,MatInputModule, MatButtonModule, MatCardModule, MatIconModule,MatSelectModule, LoadingComponent,NgOtpInputModule],
  templateUrl: './form-pin.component.html',
  styleUrl: './form-pin.component.css'
})
export class FormPinComponent {

  disabled = true;

  @Input() title = "";
  @Input() loading = false;
  @Input() btnRecordar = false;
  @Input() btnRegistrarse = false;
  @Input() btnIniciarSesion = false;
  @Input() pantalla = '';
  

  @Output() onSubmit = new EventEmitter<void>();
  @Output() registrarPin = new EventEmitter<void>();
  @Output() ingresarClave = new EventEmitter<void>();
  @Output() cambiarClave = new EventEmitter<void>();
  @Output() registrarse = new EventEmitter<void>();
  @Output() recordar = new EventEmitter<void>();
  @Output() iniciarSesion = new EventEmitter<void>();

  otp: any ;
  showOtpComponent = true;
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;


  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };


  onOtpChange(otp: any){
    this.otp = otp;
    if(otp.length == 4){
      this.disabled = false;
    }else{
      this.disabled = true;
    }
  }
  
  enviar(){
    
    if(this.pantalla == "ingresarPin"){
      this.registrarPin.emit(this.otp);
    
    }else if( this.pantalla == "ingresarClave" ){
      this.ingresarClave.emit(this.otp);
    
    }else if( this.pantalla == "cambiarClave" ){
      this.cambiarClave.emit(this.otp);
    }
  
  }
  
  register(){
    this.registrarse.emit();
  }

  remember(){

  }

  logIn(){
    this.iniciarSesion.emit();
  }

}
