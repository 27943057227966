<div>
    <div class="m-div-header">
        <div>
            <img class="m-img-logo" src="assets/image/Logo-Oscuro.png" alt="Logo">
        </div>
        <h1 class="m-title-head">{{title}}</h1>
        <img class="m-img_leaft_head" src="assets/image/icon_header_rigth_verde_blanco.png" alt="Logo-izq">
    </div>
    <div class="m-div-h">
        <h3>Saldo disponible</h3>
        <h1>{{ saldo }}</h1>
    </div>
</div>