<div class="m-div-container">
    <app-header title="TIENDA"/>
    <div class="m-div-boton">
      <!--   <h1>TIENDA</h1> -->
        <div>
            <button mat-stroked-button (click)="transporte()">
                <img src="../../../../../assets/image/icon_market_taxi.png" alt="transporte">
                <p>Servicios de transporte</p>
            </button>
        
            <!--<button mat-stroked-button (click)="gasolineras()">
                <img src="../../../../../assets/image/icon_market_gasolina.png" alt="gasolineras">
                <p>Gasolineras</p>
            </button>
            
            <button mat-stroked-button (click)="alidos()">
                <img src="../../../../../assets/image/icon_market_aliado.png" alt="alidos">
                <p>Aliados</p>
            </button>
            
            <button mat-stroked-button (click)="talleres()">
                <img src="../../../../../assets/image/icon_market_taller.png" alt="taller">
                <p>Talleres</p>
            </button>-->
            
        </div>
        
    </div>

</div>
