@if( pantalla == "recordar" ){
    <app-form-login
        title="Cambiar clave"
        [loading]="loading"
        (registrarse)="registrarse()"
        (onSubmit)="onSubmit($event)"
    />
}@else if(pantalla == 'ingresarPin'){
    <app-form-pin
        title="Ingresar PIN"
        [pantalla]="pantalla"
        [loading]="loading"
        (registrarPin)="registrarPin($event)"
        (registrarse)="registrarse()"
    />
}@else if(pantalla == 'cambiarClave'){
    <app-form-pin
        title="Cambia tu clave"
        [pantalla]="pantalla"
        [loading]="loading"
        (cambiarClave)="cambiarClave($event)"
    />
}