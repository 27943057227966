import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InicioService } from '@app/modules/home/services/inicio/inicio.service';
import { ISaldo } from '@app/modules/home/models/inicio/inicio-interface';
import { UtilsService } from '@app/shared/services/utils.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginService } from '@app/modules/auth/services/login.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  saldo = '0'; 
  @Input() title  = '';

  constructor(private inicioService: InicioService, private utilService: UtilsService, private router: Router, private loginService: LoginService){}
  
  ngOnInit(): void {
    this.inicioService.getSaldo().subscribe((resp:ISaldo)=>{
      if(resp.state){
        this.saldo = resp.response.cuenta_saldo_actual;
        this.inicioService.saldo.update(current=> current = resp.response.cuenta_saldo_actual);
      }
    }, (error:HttpErrorResponse)=>{

      if( error.status == 403 ){
        this.utilService.showAlert(error.message,'Error!','warning');
       
      }else{
        this.utilService.showAlert(error.message,'Error!','error');
      }

    });
  }

}
