import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-concepto-empresa',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatInputModule, MatIconModule, ReactiveFormsModule],
  templateUrl: './concepto-empresa.component.html',
  styleUrl: './concepto-empresa.component.css'
})
export class ConceptoEmpresaComponent {

  txtValorCtrl     = new FormControl('', [Validators.required]);
  cmbComercios = new FormControl('',[Validators.required]);
  cmbConceptos = new FormControl('',[Validators.required]);


  @Input() loading  = false;
  @Input() visibleComercios  = false;
  @Input() visibleConceptos  = false;
  @Input() VisibleValor      = false;
  @Input() titleCmbComercio = '';
  @Input() titleCmbConcepto = '';


  @Input() comercios: any = [];
  @Input() conceptos: any = [];

  @Output() onChangeComercio  = new EventEmitter<void>();
  @Output() onChangeConcepto  = new EventEmitter<void>();
  @Output() ingresarValor     = new EventEmitter<string>();
  @Output() cobroTransaccion  = new EventEmitter<void>();


  onChangeEmpHandler(event: any){
    this.onChangeComercio.emit( event.value );
  }

  onChangeConceptoHandler(event: any){
    this.onChangeConcepto.emit( event.value );
  }


  handleOnSubmit(){
    this.cobroTransaccion.emit();
  }

  keyUpHandler(event: any){

    const value  = this.txtValorCtrl.value;
    const valorSinComas = value!.replace(/[.,]/g,'');
    const primerDigito = valorSinComas?.substring(0,1);

    if( isNaN(parseInt(value!)) ){
      this.txtValorCtrl.setValue('');
      return;
    }

    if( primerDigito == '0' ){
      this.txtValorCtrl.setValue('');
      return;
    }

    const formato = parseFloat(valorSinComas!).toLocaleString("es-CO").toString();
    this.txtValorCtrl.setValue( formato );
    this.ingresarValor.emit(valorSinComas);
  }

}
