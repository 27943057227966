<app-header title="Solicitudes" />
<div class="m-div-container">

    <div class="formulario">

        <div class="m-div-container">
            <div class="m-div-container" *ngIf="solicitudesRetirosList.length == 0">
                <h2>No se encontraron resultados</h2>
            </div>
            
            <div class="m-div-container" *ngIf="solicitudesRetirosList.length > 0">
                <h2>Tus solicitudes de retiro:</h2>
                <div class="card-container">
                    <mat-card *ngFor="let solicitud of solicitudesRetirosList">
                    <mat-card-content>
                        <p>Solicitud: {{ solicitud.dissol_codigo }}</p>
                        <p>Valor: ${{ solicitud.dissol_valor }}</p>
                        <p>Banco : {{ solicitud.achban_nombre }}</p>
                        <p>Cuenta: {{ solicitud.usubancue_numero_cuenta }}</p>
                        <p>Identificacion: {{ solicitud.usubancue_identificacion }}</p>
                        <p>Nombre: {{ solicitud.usubancue_nombre_completo }}</p>
                        <p>Estado: {{ solicitud.dissol_estado_transaccion    }}</p>
                        <p>Fecha: {{ solicitud.dissol_fecha_creacion }}</p>
                       
                    </mat-card-content>
                </mat-card>
                
                </div>
            </div>

        </div>

    </div>
</div>
