<app-header title="Notificaciones" />

<div class="m-div-container">

    @if(!ultimaPagina ){
        <div class="m-div-nextpage">
            <button mat-fab color="primary" (click)="nextPage(1)">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    }

    <div class="m-div-card">
    @if( loading ){
        <div>
            <app-loading [tamano]="50" />
        </div>
    }@else{

        @for (noti of notificaciones; track noti.notificacion_codigo) {
            <mat-card>

                <mat-card-header>
                    <mat-card-title>
                        <p>Código:
                            <span>{{noti.notificacion_codigo}}</span>
                        </p>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <div class="m-div-content">
                        <p>{{noti.mensaje}}</p>
                    </div>
                </mat-card-content>

                <mat-card-footer>
                    <p>{{noti.fecha_creacion}}</p>
                </mat-card-footer>
            </mat-card>
        }@empty {
            <h1>No tiene Notificaciones</h1>
        }   
    }
    </div>

</div>