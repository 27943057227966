import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { IPqr } from '../../models/pqr/pqr-interface';

@Injectable({
  providedIn: 'root'
})
export class PqrService {


  URLBASE = environment.URLBASE;

  constructor(private http: HttpClient) { }

  registrarPqr(payload: any): Observable<IPqr> {
    let comercio_codigo = JSON.parse("" + localStorage.getItem('user-empresa'))[0].comercio_codigo;
    payload.comercio_codigo = comercio_codigo;
    return this.http.post<IPqr>(`${this.URLBASE}/app/pqr/`, payload).pipe(map((resp: IPqr) => resp));
  }

  getPqrs(page: number) {
    let comercio_codigo = JSON.parse("" + localStorage.getItem('user-empresa'))[0].comercio_codigo;
    return this.http.get(`${this.URLBASE}/app/pqr/` + comercio_codigo + `/` + page).pipe(map((resp: any) => resp));
  }
}
