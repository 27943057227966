import { Routes } from '@angular/router';
import { LoginComponent } from './modules/auth/pages/login/login.component';
import { RecordarComponent } from './modules/auth/pages/recordar/recordar.component';
import { RegistrarseComponent } from './modules/auth/pages/registrarse/registrarse.component';
import { authGuard } from './modules/auth/guards/auth.guard';
import { InicioComponent } from './modules/home/pages/inicio/inicio.component';
import { ActualizaDatosPersonalesComponent } from './modules/home/pages/actualiza-datos-personales/actualiza-datos-personales.component';
import { getSolicitudRetirosComponent } from './modules/home/pages/solicitud-retiros/solicitud-retiros.component';
import { InscripcionCuentaBancoComponent } from './modules/home/pages/inscripcion-cuenta-banco/inscripcion-cuenta-banco.component';
import { PerfilComponent } from './modules/home/pages/perfil/perfil.component';
import { PqrComponent } from './modules/home/pages/pqr/pqr.component';
import { TiendaComponent } from './modules/home/pages/tienda/tienda.component';
import { HistoricoComponent } from './modules/home/pages/historico/historico.component';
import { TransportesComponent } from './modules/home/pages/tienda/transportes/transportes.component';
import { SacarPlataComponent } from './modules/home/pages/transaciones/sacar-plata/sacar-plata.component';
import { EditPhoneComponent } from './modules/home/pages/perfil/edit-phone/edit-phone.component';
import { ExtractoComponent } from './modules/home/pages/perfil/extracto/extracto.component';
import { NotificacionesComponent } from './modules/home/pages/notificaciones/notificaciones.component';


export const routes: Routes = [

    {
        path:'', 
        component: InicioComponent,
        title:'inicio',
        canActivate: [authGuard]
    },
    {
        path:'historico', 
        component: HistoricoComponent,
        title:'historico',
        canActivate: [authGuard]
    },
    {
        path:'inicio', 
        component: InicioComponent,
        title:'inicio',
        canActivate: [authGuard]
    },
    {
        path:'datos-personales', 
        component: ActualizaDatosPersonalesComponent,
        title:'Datos-Personales',
        canActivate: [authGuard]
    },
    {
        path:'solicitud-retiros', 
        component: getSolicitudRetirosComponent,
        title:'Solicitud-Retiros',
        canActivate: [authGuard]
    },
    {
        path:'inscripcion-cuenta', 
        component: InscripcionCuentaBancoComponent,
        title:'inscripcion-Cuenta',
        canActivate: [authGuard]
    },
    {
        path:'perfil', 
        component: PerfilComponent,
        title:'Perfil',
        canActivate: [authGuard]
    },
    {
        path:'editartelefono', 
        component: EditPhoneComponent,
        title:'Editartelefono',
        canActivate: [authGuard]
    },
    {
        path:'cambiarClave/:1', 
        component: RecordarComponent,
        title:'Cambiar Clave',
        canActivate: [authGuard]
    },
    {
        path:'extracto', 
        component: ExtractoComponent,
        title:'Extracto',
        canActivate: [authGuard]
    },
    {
        path:'pqr', 
        component: PqrComponent,
        title:'Pqr',
        canActivate: [authGuard]
    },
    {
        path:'notificaciones', 
        component: NotificacionesComponent,
        title:'notificaciones',
        canActivate: [authGuard]
    },
    {
        path:'tienda', 
        component: TiendaComponent,
        title:'Tienda',
        canActivate: [authGuard]
    },
    {
        path:'sacarplata', 
        component: SacarPlataComponent,
        title:'SacarPlata',
        canActivate: [authGuard]
    },
    {
        path:'transportes', 
        component: TransportesComponent,
        title:'transportes',
        canActivate: [authGuard]
    },
    {
        path:'recordar', 
        component: RecordarComponent,
        title:'Recordar',
        //canActivate: [authGuard]
    },
    {
        path:'registrarse', 
        component: RegistrarseComponent,
        title:'Registrarse',
        //canActivate: [!authGuard]
    },
    {
        path:'login', 
        component: LoginComponent,
        title:'Login',
       //canActivate: [authGuard]
    },
    {
        path:'**', 
        component: InicioComponent,
        title:'Inicio',
        canActivate: [authGuard]
    },


];
