<div class="m-div-container-cuenta">
    
    @if( loading ){
        <div>
            <app-loading [tamano]="50"/>
        </div>
    }@else{

        @for (cuenta of cuentas; track cuenta.cuenta) {
        <mat-card>
            <mat-card-content>
                    <div class="m-div-content">
                        <p>{{cuenta.achban_nombre}}</p>
                        <span>{{cuenta.usubancue_numero_cuenta}}</span>
                    </div>
                    <div class="m-div-content">
                        <p>Fecha Inscripción:</p>
                        <span>{{cuenta.usubancue_fecha_inscripcion | date:"yyyy-MM-dd"}}</span>
                    </div>
                    <div class="m-div-content">
                        <p>Tipo Cuenta:</p>
                        <span>{{cuenta.tipo_cuenta_nombre}}</span>
                    </div>
                    <div class="m-div-content">
                        <p>Identificación:</p>
                        <span>{{cuenta.usubancue_identificacion}}</span>
                    </div>
                    <div class="m-div-content">
                        <p>Nombre:</p>
                        <span>{{cuenta.usubancue_nombre_completo}}</span>
                    </div>
                    <button mat-flat-button (click)="deleteCuenta(cuenta.usubancue_codigo)"><mat-icon>delete</mat-icon></button>
                </mat-card-content>
            </mat-card>
        }@empty {
            <h1>No tiene cuentas registradas</h1>
        }
    }

</div>