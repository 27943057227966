<mat-toolbar>
    <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
    <div>
        <img src="../../../../../assets/image/logo_horizontal_azul_verde_header.png" height="40px" alt="mobipay">
    </div>
</mat-toolbar>

<mat-drawer-container class="example-container">
    <mat-drawer-content>
        <div>
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>

    <mat-drawer mode="side" #drawer style="height: 100%; padding-top: 25px; padding-bottom: 25px;" (click)="toggleDrawer()">
        <mat-list>
            @if(isLogged){

            <mat-list-item>
                <button mat-flat-button routerLink="/inicio"><mat-icon>home</mat-icon>Inicio</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/tienda"><mat-icon>add_shopping_cart-</mat-icon>Tienda</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/historico"><mat-icon>list-</mat-icon>Historico</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/solicitud-retiros"><mat-icon>account_balance</mat-icon>Solicitud Retiros</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/inscripcion-cuenta"><mat-icon>list_alt</mat-icon>Inscripción Cuenta</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/pqr"><mat-icon>support_agent-</mat-icon>PQR</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/notificaciones"><mat-icon>notifications-</mat-icon>Notificaciones</button>
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/perfil"><mat-icon>account_circle</mat-icon>Perfil</button> 
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/extracto"><mat-icon>description</mat-icon>Extracto</button> 
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/editartelefono"><mat-icon>phone</mat-icon>Editar Telefono</button> 
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button routerLink="/cambiarClave/menu"><mat-icon>key</mat-icon>Cambiar Clave</button> 
            </mat-list-item>
            <mat-list-item>
                <button mat-flat-button (click)="cambiarEmpresa()"><mat-icon>cached</mat-icon>Cambiar de Empresa</button> 
            </mat-list-item>
            <mat-list-item>
                @if( loading ){
                    <div style="display: flex;justify-content: center;">
                        <app-loading [tamano]="35" />
                    </div>
                }@else{
                    <button mat-flat-button (click)="logout()"><mat-icon>logout</mat-icon>Cerrar Sesión</button>
                }
            </mat-list-item>
        }@else{
            <mat-list-item>
                <button mat-flat-button routerLink="/login"><mat-icon>account_circle</mat-icon>Iniciar Sesión</button>
            </mat-list-item>
        }
            
        </mat-list>
    </mat-drawer>
</mat-drawer-container> 