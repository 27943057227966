import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';


import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { ResponseCobrotransaccion } from '@app/modules/home/models/transacciones/transaccion-interface';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { UtilsService } from '@app/shared/services/utils.service';


@Component({
  selector: 'app-form-retiro-nequi',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, 
            MatCardModule, MatDialogModule, ConfirmDialogComponent, MatIconModule
           ],
  templateUrl: './form-retiro-nequi.component.html',
  styleUrl: './form-retiro-nequi.component.css'
})

export class FormRetiroNequiComponent implements OnChanges {

  formRetiroNequi: FormGroup;

  @Input() loading = false;
  @Input() responseCobro?:ResponseCobrotransaccion;
  @Output() sacarPlata = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder,  public dialog: MatDialog, private utilService: UtilsService){
    
    this.formRetiroNequi = this.fb.group({
      valorRetiro: ['',[Validators.required]],
      cobroPagoElectronico: ['',[Validators.required]],
      valorTotalRetiro: ['',[Validators.required]],
      telNequi: ['', [Validators.required, this.utilService.validaCelular]],
    });
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    this.formRetiroNequi.get('valorRetiro')?.setValue( parseInt(this.responseCobro!.total).toLocaleString('es-CO').toString() );
    this.formRetiroNequi.get('cobroPagoElectronico')?.setValue( parseFloat(this.responseCobro!.cobro).toLocaleString('es-CO').toString() );
    this.formRetiroNequi.get('valorTotalRetiro')?.setValue( parseFloat(this.responseCobro!.valor).toLocaleString('es-CO').toString() );
  }


  handlerOnSubmit(){
    this.abrirDialog();
  }

  abrirDialog(){

    const valor = parseInt(this.responseCobro!.total).toLocaleString('es-CO').toString();

    const data = {
      contentCard: `Desea realizar un retiro por valor de: $ ${valor} a la cuenta: ${this.formRetiroNequi.get('telNequi')?.value}`
    }

    let dialogref = this.dialog.open(ConfirmDialogComponent, {data})
  
    dialogref.afterClosed().subscribe((result:any) => {
      if(result){
        this.sacarPlata.emit(this.formRetiroNequi);
      }
    });

  }

}