export const environment = {
    production:false,
    /**LOCAL*/
    //URLBASE:  'http://192.168.18.217:3000',
    //REDIRECT_URL_WOMPI:'http://localhost:4200/transportes',
    /**TEST*/
    URLBASE: 'https://o1boyip5ji.execute-api.us-east-2.amazonaws.com/TEST',
    REDIRECT_URL_WOMPI:'https://testapp.mobipay.com.co/transportes',
    firebase: {
        apiKey: "AIzaSyCLEZk0_-m4d8VdvY9m8_DmbsnYeCI3HXs",
        authDomain: "mobipay-91c41.firebaseapp.com",
        projectId: "mobipay-91c41",
        storageBucket: "mobipay-91c41.appspot.com",
        messagingSenderId: "74145896030",
        appId: "1:74145896030:web:b7135c513bfc6aed6286cf",
        measurementId: "G-W4C2QCLC5F"
     }, 
};