import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }


  showAlert(text: string, title: string = 'Error!', icon:any = 'error', confirmButtonText: string='Cerrar') {
    Swal.fire({
      title,
      text,
      icon,
      confirmButtonText
    });
  }

  validaCelular(control: FormControl) {

    let isValid = control.value;
    let regex = /^3[0-9]{9}$/;
    let res = regex.test(control.value);

    if (res) {
      return null;
    }
    return { validCel: isValid };
  };

  validarEmail(control: FormControl){
    
    let isValid = control.value;
    let regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    let res = regex.test(control.value);

    if (res) {
      return null;
    }
    return { validCel: isValid };

  }


  numeroLengthMinMax( min: number, max: number) {
    return (control: FormControl) => {

      let  value = control.value;
      
      if ( value == null || value == undefined ){
        return null;
      }
      
      let isValid = value.toString().length >= min && value.toString().length <= max;
      
      return isValid ? null : { 'minMaxLength': { requiredMin: min, requiredMax: max } };

    }
  
  }

  quitarSeparadorMiles(valor: string){
    if (valor){
      return valor.replace(/[.,]/g,'');
    }else{
      return null;
    }
  }

  getTiposIdentificacion(){
    if (localStorage.getItem('utils')){
      return  JSON.parse(localStorage.getItem('utils')!).response.tipo_identificacion;
    }else{
      return null
    }
  }

  getTipoCuentaBanco(){
    if (localStorage.getItem('utils')){
      return  JSON.parse(localStorage.getItem('utils')!).response.tipo_cuenta_banco;
    }else{
      return null
    }
  }

  getAchBancos(){
    if (localStorage.getItem('utils')){
      return  JSON.parse(localStorage.getItem('utils')!).response.ach_banco;
    }else{
      return null
    }
  }
  
  getGeneros(){
    if (localStorage.getItem('utils')){
      return  JSON.parse(localStorage.getItem('utils')!).response.tipo_genero;
    }else{
      return null
    }
  }
  
  getCiudades(){
    if (localStorage.getItem('utils')){
      return  JSON.parse(localStorage.getItem('utils')!).response.ciudad;
    }else{
      return null
    }
  }

  getComercio(){
    if (localStorage.getItem('user-empresa')){
      return  JSON.parse(localStorage.getItem('user-empresa')!);
    }else{
      return null
    }
  }
  getUsuario(){
    if (localStorage.getItem('user-billetera')){
      return  JSON.parse(localStorage.getItem('user-billetera')!);
    }else{
      return null
    }
  }

  getTipoPqr(){
    if (localStorage.getItem('utils')){
      return  JSON.parse(localStorage.getItem('utils')!).response.tipo_pqr;
    }else{
      return null
    }
  }

  getParametro(name:string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  splitByMultipleDelimiters(str: string, delimiters: any) {
    let result = [str];
    for (let delimiter of delimiters) {
      result = result.flatMap(s => s.split(delimiter));
    }
    return result.filter(Boolean); // Eliminar cadenas vacías
  }

}
