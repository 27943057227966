import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InicioService } from '@home/services/inicio/inicio.service';
import { UtilsService } from '@shared/services/utils.service';
import { IInicio } from '@home/models/inicio/inicio-interface';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { MovimientosComponent } from '@home/components/inicio/movimientos/movimientos.component';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicio',
  standalone: true,
  imports: [CommonModule, LoadingComponent, HeaderComponent, MovimientosComponent, MatButtonModule],
  templateUrl: './inicio.component.html',
  styleUrl: './inicio.component.css'
})
export class InicioComponent implements OnInit {

  loading = false;

  data: any = [];

  constructor(private inicioService: InicioService, private utilService: UtilsService, private router: Router) { }


  ngOnInit(): void {
    this.loading = true;
   
    this.inicioService.getHistorial().subscribe((resp: IInicio) => {
    
      this.loading = false;
         
      if (resp.state) {
        
        if (!resp.response.datos) {
          let icon = 'warning';
          let title = 'Sin datos';
          this.utilService.showAlert(resp.message, title, icon);
        }else{
          this.data = resp.response.datos;
        }

      }else{
        this.utilService.showAlert(resp.message);
      }

    }, err => {
      
      this.loading = false;
      console.log('Error',err);
    });

  }

  pagar(){
    this.router.navigate(['/tienda']);
  }

  sacarPlata(){
    this.router.navigate(['/sacarplata']);
  }

}

