<app-header 
    title="Sacar plata"
/>

<section>
    @if( loading ){
        <div>
            <app-loading [tamano]="35" />
        </div>
    }
    
    @if( pantalla == 'retiro' ){
        <app-concepto-empresa 
            titleCmbComercio = "Desde"
            [visibleComercios] = true
            [VisibleValor] = true
            [loading] = "loading"
            [comercios]="comercios"
            (onChangeComercio)="onChangeComercio($event)"
            (ingresarValor)="ingresarValor($event)"
            (cobroTransaccion)="cobroTransaccion()"
        />
    }@else {
        <app-form-retiro-nequi 
            [loading] = "loading"
            [responseCobro]="responseCobro"
            (sacarPlata)="sacarPlata($event)"
        />
    }
</section>


