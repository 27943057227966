import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { EmpresasUsuario, IUtilities } from '../../models/validaridentificador';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-registrar-empresa',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule, MatCardModule, MatSelectModule, MatIconModule ,LoadingComponent],
  templateUrl: './registrar-empresa.component.html',
  styleUrl: './registrar-empresa.component.css'
})
export class RegistrarEmpresaComponent  implements OnInit {


  formRegEmpresa: FormGroup;

  comercios:EmpresasUsuario[] = [];
  loading = false;

  @Output() closeFrmRegEmpresa = new EventEmitter<boolean>();
  @Output() onRegistrarEmpresa = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private loginService: LoginService){
    this.formRegEmpresa = this.fb.group({
      cmbEmpresa: ['', [Validators.required]] 
    })
  }
  
  ngOnInit(): void {
    this.getUtils();
  }


  handlerOnSubmit(){
    let control = this.formRegEmpresa.get('cmbEmpresa')?.value;

    const comercio = this.comercios.filter(res=>{
      return res.comercio_codigo == control;
    });

    this.onRegistrarEmpresa.emit(comercio);
  }


  handlerClose(){
    this.closeFrmRegEmpresa.emit(false);
  }


  getUtils(){
    this.loading = true;
    this.loginService.getUtilidades().subscribe((resp:IUtilities ) =>{
      this.loading = false;
      this.comercios = resp.response.empresas_usuario;
    }, error=>{
        this.loading = false;
    });
  }

}