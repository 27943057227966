import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

/**COMPONENTES DE ANGULAR MATERIAL */
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

/**COMPONENTES */
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

/**SERVICE*/
import { UtilsService } from '../../../../shared/services/utils.service';


@Component({
  selector: 'app-form-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule,
    LoadingComponent, MatCardModule, MatIconModule, MatSelectModule],
  templateUrl: './form-login.component.html',
  styleUrl: './form-login.component.css'
})
export class FormLoginComponent {

  form: FormGroup;
  
  @Input() title = '';
  @Input() loading = false;
  @Input() btnRegistrarse = false;
  @Input() btnRecordar = false;
  @Input() btnInicioSesion = false;

  @Output() registrarse = new EventEmitter<void>();
  @Output() recordar = new EventEmitter<void>();
  @Output() iniciarSesion = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder, private utilService: UtilsService ) {

    this.form = this.fb.group({
      indicativo: ['', Validators.required],
      celular: ['', [Validators.required, this.utilService.validaCelular]],
    });

  }
  
  enviar(){
    this.onSubmit.emit(this.form);
  }
  
  register(){
    this.registrarse.emit();
  }

  remember(){
    this.recordar.emit();  
  }

  logIn(){
    this.iniciarSesion.emit(); 
  }

}
