import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs';
import { IInicio, ISaldo } from '../../models/inicio/inicio-interface';

@Injectable({
  providedIn: 'root'
})
export class InicioService {

  URLBASE = environment.URLBASE;

  saldo = signal('0');
   
  constructor(private http: HttpClient) {  }

  getHistorial(){
    return this.http.get<IInicio>(`${this.URLBASE}/app/billetera/historial/1`);
  }

  getSaldo(){
    const url = `${this.URLBASE}/app/billetera/saldo`;
    return this.http.get<ISaldo>(url);
  }

}
