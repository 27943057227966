import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ICobroTransaccion, IExtrato, INotificaciones, ITransaccionRetiroNequi } from '../../models/transacciones/transaccion-interface';
import { IResponseValidar } from '../../models/tienda/transporte-interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransaccionService {

  constructor(private http:HttpClient) { }

  URL_BASE = environment.URLBASE;

  getCobroNequi(payload: any){
    const  url = `${this.URL_BASE}/app/transaccion/cobro`;
    return this.http.post<ICobroTransaccion>(url, payload);
  }

  retiroNequi(payload: any){
    const  url = `${this.URL_BASE}/app/nequi/retiro`;
    return this.http.post<ITransaccionRetiroNequi>(url, payload);
  }
  
  getExtrato(payload: any){
    const  url = `${this.URL_BASE}/app/extracto`;
    return this.http.post<IExtrato>(url, payload);
  }

  validarPago(payload: any){
    const  url = `${this.URL_BASE}/comercio/transaccion/recaudo/confirmar/id/`+payload.id;
    return this.http.get<IResponseValidar>(url).pipe(map((resp:any)=>resp ));
  }
 
  getNotificaciones(pagina: any){
    const  url = `${this.URL_BASE}/app/notificacion/${pagina}`;
    return this.http.get<INotificaciones>(url);
  }
 
}