@if(pantalla == 'login'){

    <app-form-login
        title="Iniciar Sesión"
        [loading]="loading"
        [btnRegistrarse]="true"
        [btnRecordar]="true"
        (recordar)="recordar()"
        (registrarse)="registrarse()"
        (onSubmit)="onSubmit($event)"
    />

}@else if(pantalla == 'ingresarPin'){
    <app-form-pin
        title="Ingresar PIN"
        [pantalla]="pantalla"
        [loading]="loading"
        (registrarPin)="registrarPin($event)"
        (registrarse)="registrarse()"
    />
    

}@else if(pantalla == 'ingresarClave'){
    <app-form-pin
        title="Ingresar clave"
        [pantalla]="pantalla"
        [loading]="loading"
        (ingresarClave)="ingresarClave($event)"
        (registrarse)="registrarse()"
    />
}@else if( pantalla == 'ingresarEmpresa') {
    <div class="m-div-container">
        <h2>Por favor seleccione la empresa</h2>
        <div class="m-div-empresa">

            <div class="m-select-empresa">
                <mat-form-field>
                    <mat-label>Empresa</mat-label>
                    <mat-select (selectionChange)="onChangeEmpresa($event)">
                      @for (empresa of empresas; track empresa) {
                        <mat-option [value]="empresa.comercio_codigo">{{empresa.comercio_nombre}}</mat-option>
                      }
                    </mat-select>
                </mat-form-field>
                <div>
                    <button mat-mini-fab matTooltip="Accent" color="primary" (click)="frmRegEmpresaVisible=!frmRegEmpresaVisible">
                        <mat-icon>add</mat-icon>
                      </button>
                </div>
            </div>
              @if( loading ){
                <div>
                    <app-loading [tamano]="35" />
                </div>
            }
            <div>
                <button mat-flat-button (click)="onSelecionarEmpresa()" [disabled] = "btnEmpresa" color="primary">Ingresar</button>
            </div>
        </div>
    </div>

}


@if (frmRegEmpresaVisible){
    <div class="m-div-registro-empresa">
        @if( loading ){
            <div style="position: absolute; padding-top: 250px; z-index: 9999;">
                <app-loading [tamano]="35" />
            </div>
        }
        <app-registrar-empresa
        (closeFrmRegEmpresa)="closeFrmRegEmpresa($event)"
        (onRegistrarEmpresa)="onRegistrarEmpresa($event)"
          
        />
    </div>
}