<div class="m-div-container-dialog">
    <h2 mat-dialog-title>{{titleModal}}</h2>
    <mat-dialog-content>
    
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{titleCard}}</mat-card-title>
                <mat-card-subtitle>{{subTitleCard}}</mat-card-subtitle>
            </mat-card-header>
    
            <mat-card-content>
                {{contentCard}}
            </mat-card-content>
        </mat-card>
    
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="false" ><mat-icon>close</mat-icon> {{titleBtnCancel}}</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial><mat-icon>done</mat-icon>{{titleBtnOk}}</button>
    </mat-dialog-actions>
</div>