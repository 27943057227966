<section>
    <mat-card>
        <mat-card-header>
            <h1>Desde Nequi</h1>
        </mat-card-header>
        <form [formGroup]="formRetiroNequi" (ngSubmit)="handlerOnSubmit()">
            <mat-form-field>
                <mat-label>Valor a retirar</mat-label>
                <div>
                    <mat-icon style="margin-right: 10px;">attach_money</mat-icon>
                    <input matInput formControlName="valorRetiro" [readonly]="true">
                </div>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Cobro pago electrónico</mat-label>
                <div>
                    <mat-icon style="margin-right: 10px;">attach_money</mat-icon>
                    <input matInput formControlName="cobroPagoElectronico" [readonly]="true">
                </div>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Total a retirar</mat-label>
                <div> 
                    <mat-icon style="margin-right: 10px;">attach_money</mat-icon>
                    <input matInput formControlName="valorTotalRetiro" [readonly]="true">
                </div>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Cuenta Nequi</mat-label>
                <div>
                    <input matInput formControlName="telNequi" type="number">
                    <mat-icon>phone_android</mat-icon>
                </div>
                @if (formRetiroNequi.get('telNequi')?.invalid && formRetiroNequi.get('telNequi')?.touched) {
                    <small>El celular no es válido</small>
                }
            </mat-form-field>
            @if(!loading){
                <button mat-flat-button [disabled]="formRetiroNequi.invalid" type="submit" color="primary">Sacar plata</button>
            }
        </form>
    </mat-card>
</section>