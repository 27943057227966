import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { UtilsService } from '@app/shared/services/utils.service';
import { LoginService } from '@app/modules/auth/services/login.service';
import { ITercero } from '@app/modules/auth/models/validaridentificador';

@Component({
  selector: 'app-edit-phone',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatCardModule, MatFormFieldModule, LoadingComponent, MatSelectModule, MatIconModule, MatInputModule],
  templateUrl: './edit-phone.component.html',
  styleUrl: './edit-phone.component.css'
})

export class EditPhoneComponent implements OnInit {

  title= 'Editar teléfono';
  loading = false;
  userdata:any = {}
  userTercero:any = {}
  formPhone: FormGroup;

  constructor(private fb:FormBuilder, private utilService: UtilsService, private loginService: LoginService){

    this.formPhone = this.fb.group({
      indicativo: ['',[Validators.required]],
      celular: ['',[Validators.required, utilService.validaCelular]]
    });

  }
  
  ngOnInit(): void {
    console.log('oninit');
    this.getTercero();
    this.getUserData();
  }

  enviar(){

    this.loading = true;

    const payload = {
                      codigo_tercero: this.userTercero.tercero_codigo,
                      tipo_identificacion: this.userdata.usuario_tipo_identificacion,
                      numero_identificacion: this.userdata.usuario_identificacion,
                      numero_telefono: this.formPhone.get('celular')?.value,
                      indicativo: this.formPhone.get('indicativo')?.value,
                      usuario_state: this.userdata
                    };  
                  
    this.loginService.editarPhone(payload).subscribe((resp:any)=>{
      this.loading = false;

      if(resp.state){
        this.utilService.showAlert(resp.message, 'Exito', 'success');
      }else{
        this.utilService.showAlert(resp.message);
      }

    }, error=>{
      console.log('Error',error);
    });

  }

  getUserData(){
    this.userdata = this.utilService.getUsuario();
  }

  getTercero(){
    this.loading = true;
    this.loginService.consultarDatosUsuario().subscribe((resp:ITercero)=>{
      this.loading = false;

      if(resp.state){
        this.userTercero = resp.response;
        
        if(this.userTercero.tercero_telefono){
          const phone = (this.userTercero.tercero_telefono).slice(-10);
          this.formPhone.get("celular")?.setValue(phone);
          this.formPhone.get("indicativo")?.setValue("57");
        }
        
      }
    }, error=>{
      this.loading = false;
      console.log('Error',error);
    });

  }

}