import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { IRegisterUser, IResponseLogin, IResponseOTP, ITercero, IUtilities, IValidarIdentificador } from '@auth/models/validaridentificador';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { IPerfilUsuario } from '@app/modules/home/models/perfil/perfil-interface';
import { IInicio } from '@app/modules/home/models/inicio/inicio-interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  URLBASE = environment.URLBASE;

  constructor( private http: HttpClient, private activateRoute: ActivatedRoute )  { }

  isLogged$ = new BehaviorSubject<boolean>(false);
  private pantallaLogin = new BehaviorSubject<string>('login');
  pantalla$ = this.pantallaLogin.asObservable();
  tokenTmp = '';

  setPantalla(pantalla: string) {
    this.pantallaLogin.next(pantalla);
  }

  getPantalla() {
    return this.pantallaLogin.getValue();
  }

  login(payload:any):Observable<IResponseLogin>{
    return this.http.post(`${this.URLBASE}/app/auth/login`,payload).pipe(map((resp:any)=>resp));
  }

  validarIdentificador(payload:any):Observable<IValidarIdentificador>{
    return this.http.post(`${this.URLBASE}/app/auth/validar-identificador`, payload).pipe(map((resp:any)=>resp));
  }

  validarOtp(payload:any):Observable<IResponseOTP>{
    return this.http.put(`${this.URLBASE}/app/auth/validar-identificador`, payload).pipe(map((resp:any)=>resp));
  }
  
  validarCuentaBilletera(payload:any):Observable<any>{
    return this.http.post(`${this.URLBASE}/app/auth/validar-cuenta-billetera`, payload);
  }

   registrar(payload:any):Observable<IRegisterUser>{
    return this.http.post<IRegisterUser>(`${this.URLBASE}/app/auth/registrar`, payload).pipe(map((resp:IRegisterUser)=>resp));
  }

  recordar(payload: any){
    return this.http.post(`${this.URLBASE}/app/auth/recordar`, payload).pipe(map((resp:any)=>resp));
  }
  
  editarPhone(payload: any){
    const url = `${this.URLBASE}/app/usuario/actualizarCelularUsuario`;
    return this.http.post(url, payload);
  }
  
  consultarDatosUsuario(){
    const url = `${this.URLBASE}/app/usuario/datosusuario`;
    return this.http.post<ITercero>(url, null);
  }

  setUserLocalStorage(key: string, valor:any){
    localStorage.setItem(key, JSON.stringify(valor));
    this.isLogged();
  }

  getUserLocalStorage(key:string){

    let datosUser:any;
    datosUser = localStorage.getItem(key);

    if(datosUser != ""){
      datosUser = JSON.parse(datosUser);
    }

    return datosUser;

  }


  isLogged(){
    
    let logged = false;
    let user:any = this.getUserLocalStorage("user-billetera");
    
    if ( user != null ){
        if (user.token != undefined && this.getPantalla() == 'ingresarEmpresa'){
          logged = false;
        }else if( user.token != undefined ){
          logged = true;
        }
    }

    this.isLogged$.next(logged);

    return logged; 
  }


  getIsLogged(){
   
    return this.isLogged$.asObservable();
  }


  validaCuenta(){

    //return this.http.get()
  }

  getUtilidades(): Observable<IUtilities> {
    return this.http.get<IUtilities>(`${this.URLBASE}/utils`).pipe(map(resp => resp));
  }

  getEmpresaUsuario(data: any){

    const url = `${this.URLBASE}/app/usuario/usuariocomercio`;
    this.tokenTmp = data.token;
    
    let payload = {
      usuide_identificador: data.usuide_identificador
    };
    
    return this.http.post(url, payload).pipe(map((resp:any)=>resp ));
  }

  removeLocalStorage(){

    if( this.isLogged() ){
      localStorage.removeItem("user-billetera");
      localStorage.removeItem("user-empresa");
      localStorage.removeItem("utils");
      this.isLogged$.next(false);
    }

  }

  logOut(){
    this.pantallaLogin.next('login');
    const url = `${this.URLBASE}/app/auth/logout`;
    return this.http.post(url, null);
  }

  getDatosUsuario(payload: any){
    const url = `${this.URLBASE}/app/usuario/consultadatosbasicos`;
    return this.http.post<IPerfilUsuario>(url, payload);
  }

  actualizarUsuario (payload: any){
    const url = `${this.URLBASE}/app/usuario`;
    return this.http.put<IInicio>(url, payload)
  }
  
  cambiarEmpresa(){
    this.setPantalla("ingresarEmpresa");
    this.isLogged$.next(false);
  }


  regitarEmpresaUsuario(payload:any):Observable<any>{
    return this.http.post(`${this.URLBASE}/app/auth/registrar-empresa-usuario`,payload);
  }
  
  updateBilleteraUsuario(payload:any):Observable<any>{
    return this.http.put(`${this.URLBASE}/app/auth/actualizar-billetera-usuario`,payload);
  }

}
