import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

/**COMPONENTS */
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

import { LoginService } from '../../services/login.service';
import { FormLoginComponent } from '../../components/form-login/form-login.component';
import { UtilsService } from '../../../../shared/services/utils.service';
import { IRegisterUser, IResponseOTP, IValidarIdentificador } from '../../models/validaridentificador';

import { FormPinComponent } from '../../components/form-pin/form-pin.component';
import { FormRegistroComponent } from '../../components/form-registro/form-registro.component';

@Component({
  selector: 'app-registrarse',
  standalone: true,
  imports: [CommonModule, LoadingComponent, FormLoginComponent, FormPinComponent, FormRegistroComponent],
  templateUrl: './registrarse.component.html',
  styleUrl: './registrarse.component.css'
})
export class RegistrarseComponent implements OnInit {
  
  loading = false;
  isLogged = false;
  identificador?:string;
  btnIniciarSesion = false;

  indicativo?:string;
  celular?:string;
  data:any = {};

  pantalla        = 'registrarse';
  tipo_validacion = 'REGISTRO';
  registerForm!: FormGroup;

  constructor(private loginService: LoginService, private router: Router, private utilService: UtilsService ) {}
  
  ngOnInit(): void {
    //this.isLogged = this.loginService.isLogged();
    this.validarIslogin();
  }

  /**VALIDAR IDENTIFICADOR */
  onSubmit(event:any){

    this.registerForm = event;

    if( this.isLogged ){
      return;
    }
   
    this.loading = true;
    this.identificador = `${this.registerForm.value['indicativo']}${this.registerForm.value['celular']}`; 
    this.indicativo   = this.registerForm.value['indicativo'];
    this.celular      = this.registerForm.value['celular'];

    this.data = {
                  "identificador":   this.identificador,
                  "tipo_validacion": this.tipo_validacion, 
                  "indicativo":      this.indicativo,
                  "celular":         this.celular
                };


    let payload = {
      usuide_identificador: this.identificador, 
      tipo_validacion: 'REGISTRO'
    }

    this.loginService.validarIdentificador(payload).subscribe((resp: IValidarIdentificador)=>{
      
      this.loading = false;  
      
      if (!resp.state){
        this.utilService.showAlert(resp.message);
      }else{

          this.utilService.showAlert(resp.message,'Exito','success');
          console.log('indicativo',this.indicativo, this.celular);
          this.pantalla = "ingresarPin";
          this.btnIniciarSesion = true;
      }

    }, err =>{
        this.utilService.showAlert(JSON.stringify(err));
        this.loading = false;  
    });

  }

  registrarPin(event: any){

    this.loading = true;

    let payload = {
      usuide_identificador: this.identificador, 
      otpideval_otp: event,
      tipo_validacion: this.tipo_validacion
    }


    this.loginService.validarOtp(payload).subscribe((resp:IResponseOTP)=>{
      
      this.loading = false;

      if(!resp.state){
        this.utilService.showAlert(resp.message);
      }else{

        this.pantalla         = 'registro';
        this.btnIniciarSesion = true;
    
      }

    }, err=>{
      this.utilService.showAlert(err);
      this.loading = false;
    });
  }


  onRegistrarse(event: FormGroup){
    let payload = event;
    
    this.loading = true;

    this.loginService.registrar(payload).subscribe((resp: IRegisterUser)=>{
      
      this.loading = false;
      
      if( resp.state ){
        
        this.utilService.showAlert(resp.message, 'Exito!', 'success');
        this.router.navigate(['/login']);

      }else{
        this.utilService.showAlert(resp.message);
      }
      
    }, err=>{
      this.loading = false;
      this.utilService.showAlert(JSON.stringify(err));
    });
  }

  
  iniciarSesion(){

    if( this.isLogged ){
      this.router.navigate(['/inicio']);
    }else{
      this.router.navigate(['/login']);
    }
  }


  validarIslogin(){

    this.isLogged = this.loginService.isLogged();

    if( this.isLogged ){
      this.router.navigate(['/inicio']);
    }

    return this.isLogged;
  }

}
