import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { HeaderComponent } from '@app/shared/components/header/header.component';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

import { UtilsService } from '@app/shared/services/utils.service';
import { LoginService } from '@app/modules/auth/services/login.service';

import { IPerfilUsuario } from '../../models/perfil/perfil-interface';
import { IInicio } from '../../models/inicio/inicio-interface';


@Component({
  selector: 'app-perfil',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatSelectModule, MatInputModule,
            LoadingComponent, HeaderComponent ],
  templateUrl: './perfil.component.html',
  styleUrl: './perfil.component.css'
})
export class PerfilComponent implements OnInit, AfterViewInit {

  loading = false;
  edit = false;
  empresas:any = [];
  tiposIdentificacion:any = [];
  ciudades:any = [];
  generos:any = [];
  usuarioSession:any = {};
  
  formPerfil: FormGroup; 

  constructor(private fb: FormBuilder, private utilService: UtilsService, private loginService: LoginService){
    this.formPerfil = this.fb.group({

      tipoIdentificacion: ['',[Validators.required]],
      numId: ['',[Validators.required]],
      nombre1: ['',[Validators.required]],
      nombre2: [''],
      ape1: ['',[Validators.required]],
      ape2: [''],
      empresa: ['',[Validators.required]],
      correo: ['',[Validators.required]],
      dir:  [''],
      barrio: [''],
      ciudad: [''],
      genero: [''],

    }); 
  }
  
  ngOnInit(): void {
    this.formPerfil.disable();
    this.getTiposIdentificacion();
    this.getCiudades();
    this.getGeneros();
    this.getEmpresa();
    this.getUsuarioSession();
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.consultarUsuario();
    }, 500);
   
  }
  
  actualizar(){

    this.loading = true;
    const payload = {
                      tercero_tipo_identificacion:  this.formPerfil.get('tipoIdentificacion')?.value,
                      tercero_identificacion:       this.formPerfil.get('numId')?.value,
                      tercero_primer_nombre:        this.formPerfil.get('nombre1')?.value,
                      tercero_segundo_nombre:       this.formPerfil.get('nombre2')?.value,
                      tercero_primer_apellido:      this.formPerfil.get('ape1')?.value,
                      tercero_segundo_apellido:     this.formPerfil.get('ape2')?.value,
                      tercor_correo:                this.formPerfil.get('correo')?.value,
                      terdir_direccion:             this.formPerfil.get('dir')?.value,
                      terdir_barrio:                this.formPerfil.get('barrio')?.value,
                      ciudad_codigo:                this.formPerfil.get('ciudad')?.value,
                      empresa_usuario:              this.formPerfil.get('empresa')?.value, 
                      tipo_genero:                  this.formPerfil.get('genero')?.value, 
                    };

    this.loginService.actualizarUsuario(payload).subscribe((resp:IInicio)=>{
      this.loading = false;
      if(resp.state){
        this.utilService.showAlert('Datos actualizados con éxito','Exito!','success');
      }else{
        this.utilService.showAlert(resp.message);
      }
    },error=>{
      this.loading = false;
      console.log('Error',error);
    });


  }

  consultarUsuario(){

    this.loading = true;
    const {comercio_codigo} = this.empresas[0]
    const payload = {
      usuario_identificador: this.usuarioSession.usuide_identificador,
      usuario_tipo_identificacion: this.usuarioSession.usuario_tipo_identificacion,
      usuario_identificacion: this.usuarioSession.usuario_identificacion,
      comercio_codigo
    };

    this.loginService.getDatosUsuario(payload).subscribe((resp:IPerfilUsuario)=>{
      this.loading = false;

      if(resp.state){
        this.formPerfil.get('tipoIdentificacion')?.setValue(resp.response.tercero_tipo_identificacion);
        this.formPerfil.get('numId')?.setValue(resp.response.tercero_identificacion);
        this.formPerfil.get('nombre1')?.setValue(resp.response.tercero_primer_nombre);
        this.formPerfil.get('nombre2')?.setValue(resp.response.tercero_segundo_nombre);
        this.formPerfil.get('ape1')?.setValue(resp.response.tercero_primer_apellido);
        this.formPerfil.get('ape2')?.setValue(resp.response.tercero_segundo_apellido);
        this.formPerfil.get('empresa')?.setValue(resp.response.comercio_codigo);
        this.formPerfil.get('correo')?.setValue(resp.response.tercor_correo);
        this.formPerfil.get('dir')?.setValue(resp.response.terdir_direccion);
        this.formPerfil.get('barrio')?.setValue(resp.response.terdir_barrio);
        this.formPerfil.get('ciudad')?.setValue(resp.response.ciudad_codigo);
        this.formPerfil.get('genero')?.setValue(resp.response.tipo_genero);
      }else{
        this.utilService.showAlert(resp.message);
      }

    }, error=>{
      console.log('Error',error);
    });;
  }

  editar(){
    
    this.edit = !this.edit;

    if(this.edit ){
      this.formPerfil.get('nombre1')?.enable();
      this.formPerfil.get('nombre2')?.enable();
      this.formPerfil.get('ape1')?.enable();
      this.formPerfil.get('ape2')?.enable();
      this.formPerfil.get('correo')?.enable();
      this.formPerfil.get('dir')?.enable();
      this.formPerfil.get('barrio')?.enable();
      this.formPerfil.get('ciudad')?.enable();
      this.formPerfil.get('genero')?.enable();
    }else{
      this.formPerfil.get('nombre1')?.disable();
      this.formPerfil.get('nombre2')?.disable();
      this.formPerfil.get('ape1')?.disable();
      this.formPerfil.get('ape2')?.disable();
      this.formPerfil.get('correo')?.disable();
      this.formPerfil.get('dir')?.disable();
      this.formPerfil.get('barrio')?.disable();
      this.formPerfil.get('ciudad')?.disable();
      this.formPerfil.get('genero')?.disable();
    }

  }

  getTiposIdentificacion(){
    this.tiposIdentificacion = this.utilService.getTiposIdentificacion();
  }

  getEmpresa(){
    this.empresas = this.utilService.getComercio();
  }

  getCiudades(){
    this.ciudades = this.utilService.getCiudades();
  }

  getGeneros(){
    this.generos = this.utilService.getGeneros();
  }

  getUsuarioSession(){
    this.usuarioSession  = this.utilService.getUsuario();
  }

  

}
