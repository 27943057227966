import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

/**COMPONENTES ANGULAR MATERIAL */
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDrawer, MatSidenavModule} from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

/**SERVICES */
import { LoginService } from '@auth/services/login.service';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, MatToolbarModule,MatIconModule, MatButtonModule, 
           MatListModule, RouterOutlet,RouterLink, LoadingComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit, OnDestroy {

  isLogged:boolean =false;
  logged$!: Subscription;
  loading = false;

  @ViewChild('drawer') drawer!: MatDrawer;

  constructor(private router: Router, private loginService: LoginService) {}
    
  ngOnDestroy(): void {
    this.logged$.unsubscribe();
  }
  
  
  ngOnInit(): void {
    
    setTimeout(() => {
      
      this.logged$ = this.loginService.getIsLogged().subscribe(logged=>{
        this.isLogged = logged;
      });
      
    }, 500);

  }

  toggleDrawer(): void {
    this.drawer.toggle();
  }

  logout(){
    
    if( this.isLogged ){
      this.loading = true;
      this.loginService.logOut().subscribe((resp:any)=>{
        this.loading = false;
        this.loginService.removeLocalStorage();
        this.router.navigate(['/login']);

      }, err =>{
        console.log('Error al cerrar sesion==>',err);
        this.loading = false;
        this.router.navigate(['/login']);
      });
    }
  
  }

  cambiarEmpresa(){
    this.loginService.cambiarEmpresa();
    this.router.navigate(['/login']);
  }

}
