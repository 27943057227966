<div class="m-div-container">
    <app-header title="Servicio de transporte"/>

    <div class="m-div-form">
       
        <form [formGroup]="formTransporte" (ngSubmit)="onSubmit()" class="m-form-trans">
            
            <section>
                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Número Identificación:
                    </mat-label>
                    <input matInput type="text" formControlName="numid" name="numid" readonly="readonly">

                    @if (formTransporte.get('numid')?.invalid && formTransporte.get('numid')?.touched) {
                    <small>El número no es válido</small>
                    }

                </mat-form-field>

                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Placa:
                    </mat-label>
                    <input matInput type="text" formControlName="placa" name="placa" maxlength="6" (keyup)="upper($event)" (blur)="validaCampo()" #refplaca>

                    @if (formTransporte.get('placa')?.invalid && formTransporte.get('placa')?.touched) {
                    <small>La placa no es válida</small>
                    }
                </mat-form-field>

            </section>

            <!--EMPRESA Y CONCEPTO -->
            <section>

                <mat-form-field>
                    <mat-label>Empresa</mat-label>
                    <mat-select formControlName="empresa" (selectionChange)="onChangeEmpresa()">
                        @for (empresa of empresas; track empresa) {
                        <mat-option value="{{empresa.comercio_codigo}}">
                            {{empresa.comercio_nombre}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Concepto</mat-label>
                    <mat-select formControlName="concepto" (selectionChange)="onChangeConcepto($event)">
                        @for (concepto of conceptos; track concepto.concepto_codigo) {
                            <mat-option [value] = concepto>
                                {{concepto.concepto_nombre}}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

            </section>
            @if(empcon_valor_dinamico == 'A'){
                <section>
                    <mat-form-field>
                        <mat-label>Valor a pagar:</mat-label>
                        <input matInput formControlName="valor_dinamico" name="valor_dinamico" (input)="onChangeValorDinamico($event)">
                        
                        @if (formTransporte.get('valor_dinamico')?.invalid && formTransporte.get('valor_dinamico')?.touched) {
                        <small>El número no es válido. Debe ser mayor a $5.000</small>
                        }

                    </mat-form-field>
                </section>
            }

            <section>
                @if( loading ){
                <div class="m-loading">
                    <app-loading [tamano]="35" />
                </div>
                }@else{
                    @if(valorConIva == 0){

                        <button mat-raised-button color="primary" type="submit"
                            [disabled]="formTransporte.invalid">Continuar</button>
                    }
                }
            </section>


        </form>
      
    </div>

    <div class="div-pago">

        @if( valorConIva > 0 ){
            <app-pago-servicio 
                (pagar)="pagar($event)"
                (seleccionarMedioPago)="seleccionarMedioPago($event)"
                [valorConIva] = "valorConIva"
                [loading] = "loading"
                [mediosPago]="mediosPago"
            />
        }
    
        @if( wompi ){
            <div class="m-div-wompi">
                <app-wompi 
                    [visible] = "wompi"
                    [public_key] = "public_key"
                    [valor_centavos] = "valor_centavos"
                    [ref_pago] = "ref_pago"
                    [signature] = "signature"
                    [redirect_url_wompi]="redirect_url_wompi"
                />
            </div>
        }
    </div>


</div>

