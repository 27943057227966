import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovimientosComponent } from '../../components/inicio/movimientos/movimientos.component';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { InicioService } from '../../services/inicio/inicio.service';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { IInicio } from '../../models/inicio/inicio-interface';

@Component({
  selector: 'app-historico',
  standalone: true,
  imports: [CommonModule, MovimientosComponent, HeaderComponent, LoadingComponent],
  templateUrl: './historico.component.html',
  styleUrl: './historico.component.css'
})
export class HistoricoComponent implements OnInit {
  
  data:any = [];
  loading = false;
  constructor(private movimientoService: InicioService){}
  
  ngOnInit(): void {
    this.loading = true;
    this.movimientoService.getHistorial().subscribe((resp:IInicio)=>{

      this.loading = false;
      this.data = resp;

      if (resp.response.datos) {
        this.data = resp.response.datos;
      }

    }, error=>{
      this.loading = false;
      console.log('Error',error);
    });
  }

}
