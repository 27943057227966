import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

@Component({
  selector: 'app-pago-servicio',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule, LoadingComponent],
  templateUrl: './pago-servicio.component.html',
  styleUrl: './pago-servicio.component.css'
})
export class PagoServicioComponent {

  btnPagarVisible = true; 
  @Input() loading = false;
  @Input() valorConIva = 0;
  @Input() mediosPago:any = [];
  @Output() pagar = new EventEmitter<any>();
  @Output() seleccionarMedioPago = new EventEmitter<any>();
  
  cmbMedipago = new FormControl('', [Validators.required])

  handlerPagar(){
    this.pagar.emit( this.cmbMedipago.value );
  }


  handlerOnchange(event: any){
    
    if ( event.value == '2' ){
      this.btnPagarVisible = false;
    }else{
      this.btnPagarVisible = true;
    }

    this.seleccionarMedioPago.emit(event.value)
  
  }

}
