import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from '../../../../shared/components/header/header.component';

@Component({
  selector: 'app-tienda',
  standalone: true,
  imports: [CommonModule, MatButtonModule, HeaderComponent],
  templateUrl: './tienda.component.html',
  styleUrl: './tienda.component.css'
})
export class TiendaComponent {

  constructor(private router: Router){ }

  transporte(){
    this.router.navigate(['/transportes']);
  }
  
  gasolineras(){
    this.router.navigate(['/gasolineras']);
  }
  
  alidos(){
    this.router.navigate(['/aliados']);
  }
  
  talleres(){
    this.router.navigate(['/talleres']);
  }

}
