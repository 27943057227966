import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolicitudRetirosService {

  URLBASE = environment.URLBASE;

  constructor(private http: HttpClient) {  }


  getSolicitudRetiros(){
   return this.http.get(`${this.URLBASE}/app/banco/dispersion/`).pipe(map((resp:any)=>resp ));
    
  }

}
