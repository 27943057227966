<div class="m-div-container-wompi">
    <form action="https://checkout.wompi.co/p/" method="GET">
        <!-- OBLIGATORIOS -->
        <input type="hidden" name="public-key" [value]="public_key" />
        <input type="hidden" name="currency" value="COP" />
        <input type="hidden" name="amount-in-cents" [value]="valor_centavos" />
        <input type="hidden" name="reference" [value]="ref_pago" />
        <input type="hidden" name="signature:integrity" [value]="signature"/>
        <!-- OPCIONALES -->
        <input type="hidden" name="redirect-url" [value]="redirect_url_wompi" />
  
        <button  mat-flat-button color="primary" type="submit">Pagar con Wompi</button>
      </form>
</div>
