<div class="container">

    <mat-card class="m-cl">
        <img src="../../../../../assets/image/logo_horizontal_azul_verde.png" sizes="30px" alt="Mobipay"
            class="mat-card-image">

        <mat-card-content>
            <mat-label>{{title}}</mat-label>
            <div class="m-div-opt">
                <ng-otp-input #ngOtpInput [config]="config" (onInputChange)="onOtpChange($event)"></ng-otp-input>
            </div>
            
            @if( loading ){
                <div class="m-loading">
                    <app-loading [tamano]="35" />
                </div>
            }@else{
                <div class="m-div-btn-enviar" >
                    <button mat-raised-button color="primary" [disabled]="disabled" (click)="enviar()">Continuar</button>
                </div>
            }

            <div class="m-lfrm">
                @if(btnRecordar){
                    <div>
                        <button mat-flat-button (click)="remember()">Olvidé mi clave</button>
                    </div>
                }

                @if( btnRegistrarse ){   
                    <div>
                        <button mat-flat-button (click)="register()">Registrarse</button>
                    </div>
                }
                
                @if( btnIniciarSesion ){   
                    <div>
                        <button mat-flat-button (click)="logIn()">Inicia Sesión</button>
                    </div>
                }

            </div>

        </mat-card-content>
    </mat-card>

</div>
