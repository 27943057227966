import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { HeaderComponent } from '@app/shared/components/header/header.component';
import { CommonModule } from '@angular/common';
import { UtilsService } from '@app/shared/services/utils.service';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { PqrService } from '../../services/pqr/pqr.service';
import { IPqr, Pqr, PqrMensaje } from '../../models/pqr/pqr-interface';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PqrMensajesComponent } from './pqr-mensajes.component';

@Component({
  selector: 'app-pqr',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    HeaderComponent,
    CommonModule,
    LoadingComponent,
    MatDialogModule,
    PqrMensajesComponent,
    MatIconModule
  ],
  templateUrl: './pqr.component.html',
  styleUrls: ['./pqr.component.css']
})
export class PqrComponent implements OnInit {
  loading = false;
  tiposPqr: any = [];
  pqrs: Pqr[] = [];
  pqrForm: FormGroup;
  paginaActual: number = 1;
  paginaMaxima: number = 0;

  constructor(private utilService: UtilsService, private fb: FormBuilder, private pqrService: PqrService, public dialog: MatDialog) {
    this.pqrForm = this.fb.group({
      pqr_tipo: ['', [Validators.required]],
      pqr_mensaje: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getTiposPqr();
    this.getTickets(1);
  }

  getTiposPqr() {
    this.tiposPqr = this.utilService.getTipoPqr();
  }

  getTickets(pagina: number) {
    console.log(pagina);
    this.pqrService.getPqrs(pagina).subscribe(
      (resp: IPqr) => {
        if (resp.response.datos.length > 0) {
          this.pqrs = resp.response.datos;
          console.log(this.pqrs);
          this.paginaActual = pagina;
          this.paginaMaxima = Math.ceil(resp.response.contador_registros / 5);
        }

      },
      (error) => {
        // Manejar errores aquí
        console.error('Error al obtener los tickets:', error);
      }
    );
  }
  onSubmit() {
    if (this.pqrForm.valid) {
      const payload = this.pqrForm.value;
      this.loading = true;

      this.pqrService.registrarPqr(payload).subscribe(
        (resp: IPqr) => {
          console.log('Respuesta del servicio:', resp);
          this.loading = false;
          this.pqrForm.reset();
          this.getTickets(1);
          this.utilService.showAlert(resp.message, 'Exito!', 'success');
        },
        (error) => {
          this.loading = false;
          this.utilService.showAlert("Error al registrar.");
          console.error('Error al enviar el formulario:', error);
        }
      );
    }
  }


  anterior() {
    if (this.paginaActual > 1) {
      this.getTickets(this.paginaActual - 1);
    }
  }

  siguiente() {
    if (this.paginaActual < this.paginaMaxima) {
      this.getTickets(this.paginaActual + 1);
    }
  }

  verMensajes(pqr: Pqr): void {
    let dialogref = this.dialog.open(PqrMensajesComponent, {
      data: pqr,
      width: '600px'
    })
  }

}
