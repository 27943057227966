<div class="m-div-container">
    <mat-card>
      <mat-card-header>
        <mat-card-subtitle><span>Total a pagar:</span></mat-card-subtitle>
        <mat-card-title> <p>{{ valorConIva | currency:'USD':'symbol':'1.0-0' }}</p></mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
        <mat-form-field>
          <mat-label>Medio Pago</mat-label>
          <mat-select [formControl]="cmbMedipago" (selectionChange)="handlerOnchange($event)">
            @for (medio of mediosPago; track medio.comercio_codigo) {
            <mat-option [value]="medio.comercio_codigo">{{medio.comercio_nombre}}</mat-option>
            }
          </mat-select>
            @if( cmbMedipago.hasError('required') && cmbMedipago.dirty ){
          <small>El campo es requerido</small>
          }
        </mat-form-field>
      </mat-card-content>
      
      <mat-card-actions>
        @if( !loading ){
          @if(btnPagarVisible){
            <button mat-flat-button color="primary" (click)="handlerPagar()" [disabled]="cmbMedipago.invalid" >Pagar</button>
          }
        }
      </mat-card-actions>
    </mat-card>
</div>