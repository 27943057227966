<div class="container">

    <mat-card class="m-cl">
        <img src="../../../../../assets/image/logo_horizontal_azul_verde.png" sizes="30px" alt="Mobipay"
            class="mat-card-image">
        <mat-card-content>
            <mat-card-title>{{title}}</mat-card-title>
            <form [formGroup]="formPhone" (ngSubmit)="enviar()" class="m-fl">
                <mat-form-field>
                    <mat-label>indicativo</mat-label>
                    <mat-select formControlName="indicativo">
                        @for (pais of [{codigo:57,value:"Colombia/ +57"}]; track pais) {
                        <mat-option value="{{pais.codigo}}">
                            {{pais.value}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"
                            fontIcon="settings_cell"></mat-icon>
                        Celular:
                    </mat-label>
                    <input matInput type="number" formControlName="celular">
                   
                    @if (formPhone.get('celular')?.invalid && formPhone.get('celular')?.touched) {
                        <small>El celular no es válido</small>
                    }
                </mat-form-field>

                @if( loading ){
                    <div class="m-loading">
                        <app-loading [tamano]="35"/>
                    </div>
                }@else{
                    <button mat-raised-button color="primary" type="submit"
                        [disabled]="formPhone.invalid">Guardar
                    </button>
                }
            
            </form>

        </mat-card-content>
    </mat-card>

</div>