<div class="m-div-container">

    @if(data.length > 0){

        <h1>Últimos movimientos</h1>
        <div class="div-card">
        @for (i of data; track i.transaccion_codigo) {
            <mat-card>
                <mat-card-header>
                    <mat-card-title><h4>{{i.tipmov_nombre}}</h4></mat-card-title>
                    <mat-card-subtitle>
                        Naturaleza {{i.transaccion_naturaleza}}
                    </mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                   <p>Código transacción: <span>{{i.transaccion_codigo}}</span></p> 
                   <p>Etiqueta: <span>{{i.transaccion_etiqueta}}</span></p> 
                   <p>Valor: <span>{{i.transaccion_valor}}</span></p>  
                </mat-card-content>
                <mat-card-footer>
                    <mat-card-subtitle>
                        Fecha creación: {{i.transaccion_fecha_creacion}}
                    </mat-card-subtitle>
                </mat-card-footer>
            </mat-card>
        }
    </div> 
           
    }@else{
        <h1>No se encontraron movimientos</h1>
    }

</div>