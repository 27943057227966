import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoginService } from '../services/login.service';


export const authGuard: CanActivateFn = (route, state) => {
  
  let loginService = inject(LoginService);
  let router = inject(Router);
  let islogged = loginService.isLogged();

  if( !islogged ){
    router.navigate(['/login']);
  }

  return islogged;
};
