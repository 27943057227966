import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { LoadingComponent } from '@shared/components/loading/loading.component';
import { HeaderComponent } from '@shared/components/header/header.component';

import { TiendaService } from "@home/services/tienda/tienda.service";
import { UtilsService } from '@shared/services/utils.service';
import { TransaccionService } from '@app/modules/home/services/transacciones/transaccion.service';

import { IComercioConceptosNuevo } from '@home/models/tienda/tienda-interface';
import { IConceptoValor, IReferenciaPago, IReferenciaWompi, IResponseValidar } from '@home/models/tienda/transporte-interface';
import { PagoServicioComponent } from '@app/modules/home/components/tienda/pago-servicio/pago-servicio.component';
import { ResponseLogin } from '@app/modules/auth/models/validaridentificador';
import { WompiComponent } from '@app/modules/home/components/tienda/wompi/wompi.component';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { InicioService } from '@app/modules/home/services/inicio/inicio.service';

@Component({
  selector: 'app-transportes',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatButtonModule, MatIconModule, 
            LoadingComponent, MatInputModule, HeaderComponent, PagoServicioComponent, WompiComponent
           ],
  templateUrl: './transportes.component.html',
  styleUrl: './transportes.component.css'
})

export class TransportesComponent implements OnInit, AfterViewInit {

  loading = false;

  //WOMPI
  wompi = false;
  signature = '';
  public_key= '';
  valor_centavos= 0;
  ref_pago= '';
  btnWompiDisabled = false;
  redirect_url_wompi = '';

  empresas: any;
  comercio:any;
  conceptos: any = [];
  mediosPago:any = [];
  formulario:any = [];
  categoria = {
    "tiecat_codigo": "1",
    "tiecat_nombre": "SERVICIOS DE TRANSPORTE"
  }
  empcon_valor_dinamico:string = 'I';

  //tiecat_codigo = '1';
  selectConcepto:any =[];
  vlr_libre = ''
  valorConIva = 0;
  usuario?:ResponseLogin;
  
  formTransporte: FormGroup;

  @ViewChild('refplaca' ,{ static: true }) refplaca?: ElementRef;

   constructor(private fb: FormBuilder, private tiendaService: TiendaService, private utilService: UtilsService, private router: Router, private transaccionService: TransaccionService, private renderer: Renderer2, private inicoService: InicioService){
    this.formTransporte = this.fb.group({
      numid: ['', [Validators.required]],
      placa: ['',[Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      empresa: ['',[Validators.required]],
      concepto: ['',[Validators.required]],
      valor_dinamico: ['', this.validarValorDinamico()],
      vlr_libre: ['']
    });
  }
  
  ngAfterViewInit(): void {
    
    setTimeout(() => {
      let miControl = this.renderer.selectRootElement(this.refplaca?.nativeElement); 
      this.formTransporte.get('numid')?.setValue(this.usuario?.usuario_identificacion);
      
      if(miControl){
        miControl.focus();
      }
      
    }, 500);
  }

  validarValorDinamico(): ValidatorFn{
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valorDinamico = control.value.replace(/\./g, ""); 
      if (this.empcon_valor_dinamico === 'A' && valorDinamico <= 5000) {
        return { 'Valor inválido': true };
      }
      return null;
    };
  }

  ngOnInit(): void {
   this.validarPago();
   this.setEmpresa()
   this.setMediosPago();
   this.setUser();
  }


  onSubmit(){

    let item = {};
    let codigo_concepto = this.formTransporte.get('concepto')!.value.concepto_codigo;

    this.conceptos.filter((filtro:any)=>{
      if( filtro.concepto_codigo ==  codigo_concepto){
        this.vlr_libre = filtro.concepto_valor_libre;
      }
    });
    
    item = {
      id: 'vehiculo_placa',
      value: this.formTransporte.get('placa')!.value,
      label: 'Placa'
    }
    
    this.formulario.push(item);

    item = {
      id: 'tercero_identificacion',
      value: this.formTransporte.get('numid')!.value,
      label: 'Identificacion'
    };
    
    this.formulario.push(item);

    const payload = {
        tiecat_codigo:      this.categoria.tiecat_codigo,
        comercio_codigo:    this.formTransporte.get('empresa')!.value,
        aliado_codigo:      null,
        concepto_codigo:    this.formTransporte.get('concepto')?.value?.concepto_codigo,
        formulario:         this.formulario,
        valor:              this.formTransporte.get('valor_dinamico')!.value == 0? null: this.formTransporte.get('valor_dinamico')!.value ,
        valor_libre:        this.vlr_libre
    };

    if(this.empcon_valor_dinamico == 'A'){
      this.valorConIva = this.formTransporte.get('valor_dinamico')!.value.replace(/\./g, "");
      this.vlr_libre = 'A';
    }else{
      this.loading = true;

      this.tiendaService.getConceptoValor(payload).subscribe((resp: IConceptoValor)=>{
      this.loading = false;
      
      if(resp.state){
        if(resp.response.valor_interes_fijo > 0){
          this.valorConIva = resp.response.valor_con_iva + resp.response.valor_interes_fijo;
        }else{
          this.valorConIva = resp.response.valor_con_iva;
        }
      }else{
        this.utilService.showAlert(resp.message);
      }

    }, err =>{
        this.loading = false;
        this.utilService.showAlert(err['message']);

    });
    }
 
  }

  onChangeValorDinamico(event: any){
    console.log("se cambio el valor", event);
    this.formTransporte.get('valor_dinamico')?.setValue(parseInt(event.target.value.replace(/\./g, "")).toLocaleString('es-CO').toString());
  }

  onChangeEmpresa(){
 
    this.loading = true;
    const payload =  {
                      tiecat_codigo: '1',
                      comercio_codigo: this.formTransporte.get('empresa')?.value,
                      nro_cedula: this.formTransporte.get('numid')?.value,
                      nro_placa: this.formTransporte.get('placa')?.value
                    };
    
    this.tiendaService.getComercioConceptos(payload).subscribe((resp: IComercioConceptosNuevo)=>{
 
      this.loading = false;

      if( resp.state ){
        
        resp.response.map((value:any, i: number)=>{
    
            let json_new = {
              concepto_codigo: value["concepto_codigo"],
              concepto_nombre: value["concepto_nombre"],
              concepto_valor_libre: value["concepto_valor_libre"],
              empcon_valor_dinamico: value["empcon_valor_dinamico"]
            };
            
            this.conceptos.push(json_new);
        }); 
    
      }else{
        this.loading = false;
        this.utilService.showAlert(resp.message,'Advertencia!', 'warning');
      }
    }, err=>{
      this.loading = false;
      this.utilService.showAlert(JSON.stringify(err));
    });

  }

  pagar(medio: any){

    if ( medio == 1 ){
      const valido = this.validaSaldoMobipay();
      if( valido ){
        this.pagaMobipay();
      }
    }else if(medio == 2){
      this.pagosDigitales();
    }else{
      this.utilService.showAlert('Servicio no disponible','Advertencia','warning');
    }

  }

  
  validaSaldoMobipay(){
    let valido = true;
    const saldo = this.inicoService.saldo();
    let saldoString = this.utilService.splitByMultipleDelimiters(saldo, ['$', '.']);
    const saldoActual = this.utilService.quitarSeparadorMiles(saldoString[0]);
    
    if( saldoActual && this.valorConIva ){
      if( parseInt(saldoActual) < this.valorConIva){
        const msg = `No tiene saldo suficiente en la cuenta para cancelar el concepto.\n Saldo actual ${saldo}`;
        this.utilService.showAlert(msg, 'Advertencia!', 'warning' );
        valido = false;
      }
    }else{
      valido = false;
    }

    return valido;
  }

  /**PAGO MOBIPAY*/
  async pagaMobipay(){

    let referencia  = false;
    let pagar       = false;
    let response_referencia:any;
    this.loading = true;

    const payload = {
      valor:  this.valorConIva,
      usuide_identificador: this.usuario!.usuide_identificador
    };

    /**VALIDACION DEL PAGO */
    try {
      let validaPago:any = await this.tiendaService.validarValorPago(payload);

      if(validaPago['state']){
        referencia = true;
      }else{
        this.loading = false;
        this.utilService.showAlert(validaPago['message']);
      }
      
    } catch (error: any) {
      this.loading = false;
      this.utilService.showAlert(error['message']);
    }

    /**OBTENEMOS LA REFERENCIA */
    if ( referencia ){
      response_referencia = await this.getReferencia();

      if( response_referencia.state ){
        pagar = true;
      }else{
        this.loading = false;
        this.utilService.showAlert(response_referencia.message);
        return;
      }
    }

    /**REGISTRAMOS EL PAGO */
    if( pagar ){
      try {

        let params = {
          referencia_pago:  response_referencia.response.referencia_pago  
        };

        const pago: any = await this.tiendaService.pagar(params);

        if( pago.state ){

          pagar = true;
          this.loading = false;
          this.utilService.showAlert(pago.message,'Exito!','success');
          this.router.navigate(['/tienda'])

        }else{
          this.loading = false;
          this.utilService.showAlert(pago.message);
        }

      } catch (error:any) {
        this.loading = false;
        this.utilService.showAlert(error['message']); 
      }
    }
    
  }


  /**PAGOS DIGITALES */
  async pagosDigitales(){
    this.loading = true;
    let response_referencia:IReferenciaPago;
    
    response_referencia = await this.getReferencia();
    
    if( response_referencia.state ){

      this.signature = response_referencia.response.signatureCode;

      try {
        
        const payload = {
          comercio_codigo: this.comercio.comercio_codigo,
          movimiento_valor: this.valorConIva,
          referencia_pago: response_referencia.response.referencia_pago 
        }

        //PASARELA WOMPI
        const refPasarela:IReferenciaWompi = await this.tiendaService.referenciaPasarela(payload);
        this.loading = false;
        
        if(refPasarela.state){
          this.wompi = true;
          this.redirect_url_wompi = environment.REDIRECT_URL_WOMPI;
          this.valor_centavos = this.valorConIva * 100;
          this.public_key = refPasarela.response.cuenta_pasarela_key;
          this.ref_pago = response_referencia.response.referencia_pago;
          
        }else{
          this.loading = false;
          this.utilService.showAlert(refPasarela.message);
        }
        
      } catch (error: any) {
        this.loading = false;
        this.utilService.showAlert(error['message']); 
      }

    }else{
      this.loading = false;
      this.utilService.showAlert(response_referencia.message);
    }

  }


  /**OBTENEMOS LA REFERENCIA */
  async getReferencia(){

    try {
      
      const data = {
                    tiecat_codigo:                this.categoria.tiecat_codigo, 
                    comercio_codigo:              this.empresas[0].comercio_codigo,
                    aliado_codigo:                null,
                    aliado_telefono:              "57",
                    concepto_codigo:              this.selectConcepto[0].concepto_codigo,
                    concepto_nombre:              this.selectConcepto[0].concepto_nombre,
                    formulario:                   (this.formulario.length == 0) ? [ {} ] : this.formulario,
                    valor:                        this.valorConIva,
                    valor_libre:                  this.vlr_libre,
                    usuide_identificador_aliado:  "57",
                    placa_vehiculo:               this.formTransporte.get('placa')!.value
                  };
      const referencia:any = await this.tiendaService.referenciaPago(data);            
      return referencia;
      
    }catch (error: any) {
        return error;
    }

  }

  validaCampo(){
    this.conceptos = [];
    let placa = this.formTransporte.get('placa')?.valid;
    let numid = this.formTransporte.get('numid')?.valid;
     
    if( placa  && numid  ){
      this.onChangeEmpresa();
    }else{
      this.valorConIva = 0;
    }

  }

  upper(event:any){
    const nombreControl = this.formTransporte.get('placa');
    if (event.target.value) {
      nombreControl!.setValue(nombreControl!.value.toUpperCase());
      this.valorConIva = 0;
    }
  }

  
  setEmpresa(){

    if( localStorage.getItem("user-empresa") ){

      this.empresas = JSON.parse( localStorage.getItem("user-empresa")! ) ;
      this.formTransporte.get('empresa')!.setValue(this.empresas[0].comercio_codigo);
      this.formTransporte.get('empresa')!.disable();
      this.comercio = this.empresas[0];

    }
    
  }

  setMediosPago(){
    
    if( localStorage.getItem("utils") ){
      const comercios = JSON.parse( localStorage.getItem("utils")! ).response.comercios;
      comercios.filter((filtro: any)=>{
        if( filtro.comercio_recaudo == 'A' ){
          this.mediosPago.push(filtro);
        }
      });
    }

  }

  setUser(){
    if( localStorage.getItem("user-billetera") ){
      const user = JSON.parse( localStorage.getItem("user-billetera")! );
      this.usuario = user;
    }
  }

  onChangeConcepto(event: any){

    //Para ocultar total a pagar caundo seleccionen un concepto diferente
    this.valorConIva = 0;

    if ( event.value != "" ){
      let concepto = event.value;
      let conceptoSeleccionado = {
                              concepto_codigo: concepto.concepto_codigo,
                              empcon_valor_dinamico: concepto.empcon_valor_dinamico,
                              concepto_nombre: concepto.concepto_nombre
                            };
      
      this.selectConcepto.push(conceptoSeleccionado);            
      this.empcon_valor_dinamico = concepto.empcon_valor_dinamico;     
      this.formTransporte.get('valor_dinamico')!.setValue('');
     
    }

  }

  seleccionarMedioPago(value: any ){

    if ( value ){
      if( value == '2' ){
        this.pagar(value);
      }else{
        if ( this.wompi ){
          this.wompi = false;
        }
      }
    }  
  }

  async validarPago(){
    const id = this.utilService.getParametro('id');
    if (id.length > 0){
      const payload = {
        id:      id
      };
      this.transaccionService.validarPago(payload).subscribe((resp: IResponseValidar) => {
        if(resp.status.code == 0){
          this.utilService.showAlert(resp.status.message, "Éxito", "success");
        }else{
          this.utilService.showAlert(resp.status.message, 'Error');
        }
      }, err => {
        this.utilService.showAlert('Error al validar el resultado de la transacción', 'Error');
      });
    }
  }

}
