import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICreateCuenta, ICuentaBanco, IDeleteCuenta } from '@app/modules/home/models/inscripcion-cuenta-banco/inscripcion-cuenta-interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InscripcionCuentaService {

  URL_BASE = environment.URLBASE;

  constructor( private http: HttpClient ) { }

  getCuentas(){
    const url = `${this.URL_BASE}/app/banco/cuenta`; 
    return this.http.get<ICuentaBanco>(url);
  }


  createCuentaBanco(payload: any){
    const url = `${this.URL_BASE}/app/banco/cuenta`; 
    return this.http.post<ICreateCuenta>(url, payload);
  }
  
 
  
  deleteCuentaBanco(payload: any){

    const options = {
      body: payload
    }

    const url = `${this.URL_BASE}/app/banco/cuenta`; 
    return this.http.delete<IDeleteCuenta>(url, options );
    
  }

}
