import { Injectable } from '@angular/core';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getMessaging, onMessage, getToken, Messaging } from 'firebase/messaging';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private firebaseApp: FirebaseApp|undefined;
  private messanging: Messaging|undefined;
  private token:string = '';

  constructor() {
    this.initFirebaseApp();
    this.requestPermission();
  }

  private initFirebaseApp(){
    const firebaseConfig = environment.firebase;
    console.log('firebaseConfig',firebaseConfig);
    this.firebaseApp = initializeApp(firebaseConfig);
  }

  private initFirebaseMessaging(){
    this.messanging = getMessaging(this.firebaseApp);
  
    getToken(this.messanging).then((currentToken)=> {
      console.log(currentToken);
      if(currentToken){
        this.token = currentToken;
      }else{
        console.error("No se puede obtener el token");
      }
    }).catch((err) => {
      console.error("no se pudo obtener token", err);
    })

    onMessage(this.messanging, (payload) => {
      console.log('Notificacion recibida:', payload)
    })
  }

  private requestPermission(){
    Notification.requestPermission().then((permission) => {
      if(permission == 'granted'){
        console.log("Permiso concedido");
        this.initFirebaseMessaging();
      }else{
        console.log("permiso denegado");

      }
    })
  }

  public getToken(){
    return this.token;
  }
  
  
}
