import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

/**COMPONENTS */
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { FormLoginComponent } from '../../components/form-login/form-login.component';

/**INTERFACE*/
import { IRegisterUser, IResponseOTP, IValidarIdentificador } from '../../models/validaridentificador';

/**SERVICES */
import { LoginService } from '../../services/login.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { FormPinComponent } from '../../components/form-pin/form-pin.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-recordar',
  standalone: true,
  imports: [CommonModule, LoadingComponent, FormLoginComponent, FormPinComponent],
  templateUrl: './recordar.component.html',
  styleUrl: './recordar.component.css'
})
export class RecordarComponent implements OnInit {

  loading = false;
  isLogged = false;
  btnRegistrarse = true;
  identificador?:string;
  pantalla = 'recordar';
  tipo_validacion = 'RECORDAR';
  cambiarClaveMenu = false;
  recordarForm!: FormGroup;

  constructor( private loginService: LoginService, private utilService: UtilsService, private router: Router,  private route: ActivatedRoute ) {}
  
  ngOnInit(): void {

    this.route.params.subscribe((param: Params)=>{
      
      if( Object.keys(param).length == 0 ){
        this.validarIslogin();
      }else{
        this.cambiarClaveMenu = true;
      }

    });

  }

  /**VALIDAR IDENTIFICADOR */
  onSubmit(event: FormGroup){

    this.recordarForm = event;

    //Solo valida login si no esta logeado para poder cambiar la clave dentro del menu
    if( this.cambiarClaveMenu == false ){
    
      if( this.validarIslogin() ){
        return;
      }
    
    }
   
    this.loading = true;
    this.identificador = `${this.recordarForm.value['indicativo']}${this.recordarForm.value['celular']}`; 

    let payload = {
      usuide_identificador: this.identificador, 
      tipo_validacion: this.tipo_validacion
    }

    this.loginService.validarIdentificador(payload).subscribe((resp: IValidarIdentificador)=>{
    
      this.loading = false;  

      if (!resp.state){
        this.utilService.showAlert(resp.message);
      }else{

          this.utilService.showAlert(resp.message,'Exito','success');
          this.pantalla = 'ingresarPin'
      }

    }, err =>{
        this.utilService.showAlert(JSON.stringify(err));
        this.loading = false;  
    });

  }

  registrarPin(event: any){

    this.loading = true;

    let payload = {
      usuide_identificador: this.identificador, 
      otpideval_otp: event,
      tipo_validacion: this.tipo_validacion
    }

    this.loginService.validarOtp(payload).subscribe((resp:IResponseOTP)=>{
      
      this.loading = false;

      if(!resp.state){
        this.utilService.showAlert(resp.message);
      }else{

        this.pantalla       = 'cambiarClave';
        this.btnRegistrarse = true;
      }

    }, err=>{
      this.utilService.showAlert(err);
      this.loading = false;
    });
  }

  cambiarClave(event: any){

    let clave = event;
    this.loading = true;

    let payload = {
      usuide_identificador:this.identificador,
      usuario_clave: clave
    };
    
    this.loginService.recordar(payload).subscribe((resp:IRegisterUser)=>{

      this.loading = false;
      
      if( resp.state ){
        this.utilService.showAlert(resp.message,'Exito','success');
        this.loginService.setUserLocalStorage("user-billetera",resp.response);

        this.router.navigate(['/inicio']);
      
      }else{
        this.utilService.showAlert(resp.message);
      }

    }, (err:HttpErrorResponse)=>{
      this.loading = false;
      this.utilService.showAlert(err.message,'Error!','error');
    });
  
  }


  registrarse(){
    this.router.navigate(['/registrarse']);
   
  }

  validarIslogin(){

    this.isLogged = this.loginService.isLogged();

    if( this.isLogged ){
      this.router.navigate(['/inicio']);
    }

    return this.isLogged;
  
  }

}
