import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@shared/components/header/header.component';
import { ConceptoEmpresaComponent } from '@home/components/concepto-empresa/concepto-empresa.component';
import { UtilsService } from '@app/shared/services/utils.service';
import { FormRetiroNequiComponent } from '@app/modules/home/components/transacciones/form-retiro-nequi/form-retiro-nequi.component';
import { TransaccionService } from '@app/modules/home/services/transacciones/transaccion.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ICobroTransaccion, ITransaccionRetiroNequi, ResponseCobrotransaccion } from '@app/modules/home/models/transacciones/transaccion-interface';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sacar-plata',
  standalone: true,
  imports: [CommonModule, HeaderComponent, ConceptoEmpresaComponent, FormRetiroNequiComponent, LoadingComponent],
  templateUrl: './sacar-plata.component.html',
  styleUrl: './sacar-plata.component.css'
})
export class SacarPlataComponent implements OnInit {
  
  loading = false;
  comercios: any = [];
  conceptos: any = [];
  comercioCodigo = "";
  movimiento_valor = "";
  responseCobro?: ResponseCobrotransaccion;

  pantalla = 'retiro'; 


  constructor(private utilService: UtilsService, private transaccionService: TransaccionService, private router: Router){}
  
  ngOnInit(): void {
    this.getDataLocalStorage();
  }


  getDataLocalStorage(){

    const dataEmp = JSON.parse(localStorage.getItem("utils")!).response.comercios;
   
    dataEmp.map((resp: any)=>{

      if (resp.comercio_dispersion == 'A'){
        let comercios = {
          comercio_codigo: resp.comercio_codigo,
          comercio_nombre: resp.comercio_nombre
        }

        this.comercios.push(comercios);

      }

    });
   
  }

  
  onChangeComercio(value: any){
    
    if(value != 3){
      this.utilService.showAlert('Servicio no disponible','Advertencia','warning' );
    }else{
      this.comercioCodigo = value;
    }

  }


  ingresarValor(event: any){
    this.movimiento_valor = event;
  }


  cobroTransaccion(){
    
    this.loading = true;
    const payload = {
      movimiento_valor: parseInt(this.movimiento_valor),
      tipmov_codigo: ( parseInt(this.comercioCodigo) < 1000)  ? "301" : "302", 
      comercio_codigo: this.comercioCodigo
    }

    this.transaccionService.getCobroNequi(payload).subscribe((resp:ICobroTransaccion)=>{
      this.loading = false;
    
      if(resp.state){

        this.responseCobro = resp.response;
        this.pantalla = 'nequi';

      }else{
        this.utilService.showAlert(resp.message)
      }
    },(error: HttpErrorResponse)=>{
      console.log('Error',error.message);
      this.loading = false;
      this.utilService.showAlert(error.message)
    });

  }


  sacarPlata(form: FormGroup){

    this.loading = true;
    const valorSinComas = this.utilService.quitarSeparadorMiles(form.get('valorRetiro')?.value);

    const payload =   {
      responseSuccess: "SI",
      movimiento_valor:     valorSinComas,
      identificador_nequi:  String(form.get('telNequi')?.value)
    }

    this.transaccionService.retiroNequi(payload).subscribe((resp: ITransaccionRetiroNequi)=>{
      
      this.loading = false;

      if( resp.state ){

        this.utilService.showAlert('Retiro realizado con exito', 'Exito!','success');
        this.router.navigate(['/inicio']);

      }else{
        this.utilService.showAlert(resp.message);
      }

    }, (error: HttpErrorResponse)=>{
        this.loading = false;
        console.log('Error-hhtp=>',error);
        this.utilService.showAlert(error.message);
    });

  }

}
