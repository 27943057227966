<div class="m-div-container">

    <app-header title="Cuentas" />
    
    <div class="m-div-list-cuentas">
        <app-cuenta />
    </div>
    
    @if (frmRegistrarVisible){
        <div class="m-div-registro">
            <app-registrar-cuenta (hiddeRegistrar)="hiddeRegistrar($event)" />
        </div>
    }

    <div class="m-div-add-cuentas">
        <button mat-icon-button (click)="frmRegistrarVisible=!frmRegistrarVisible">
            <mat-icon>add</mat-icon>
          </button>
    </div>



</div>
