import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { MatMomentDateModule} from '@angular/material-moment-adapter'
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableDataSource, MatTableModule} from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';

import { HeaderComponent } from '@app/shared/components/header/header.component';
import { TransaccionService } from '@app/modules/home/services/transacciones/transaccion.service';
import { UtilsService } from '@app/shared/services/utils.service';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { IExtrato } from '@app/modules/home/models/transacciones/transaccion-interface';
import jsPDF from 'jspdf';

import 'moment/locale/es';
import moment from 'moment';

var dateConfig = {
  parse: {
    dateInput: 'input',
  },
  display: {
    dateInput: 'yyyy-MM',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
}

@Component({
  selector: 'app-extracto',
  standalone: true,
  imports: [CommonModule, HeaderComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatDatepickerModule, 
            MatCardModule, MatButtonModule , LoadingComponent, MatTableModule, MatIconModule, MatMomentDateModule],
  templateUrl: './extracto.component.html',
  styleUrl: './extracto.component.css',
  providers: [ { provide: MAT_DATE_FORMATS, useValue: dateConfig }]

})

export class ExtractoComponent {

  data: any = [];

  displayedColumns: string[] = ['fecha', 'descripcion', 'valor', 'saldo'];
  dataSource = new MatTableDataSource();

  loading = false;
  maxDate: Date =  new Date();
  formExtracto: FormGroup;

  constructor(private transacionService: TransaccionService, private fb: FormBuilder, private utilService: UtilsService, private dateAdapter: DateAdapter<Date> ){

    this.dateAdapter.setLocale('es'); 

    this.formExtracto = this.fb.group({
      fechaCorte: ['', [Validators.required]]
    });
  }

 
  consultar(){

    const fechaCorte = this.getFechaCorte();
    
    if(!fechaCorte){
      return;  
    }

    let formatfecha = fechaCorte.format('YYYY-MM-D');
    
    const payload = { fecha_corte:  formatfecha }
    this.loading = true;
    
    this.transacionService.getExtrato(payload).subscribe((resp:IExtrato)=>{

      this.loading = false;

      if(resp.state){

        if(resp.response.length > 0){
          this.data = resp.response;
          this.dataSource = this.data;
         
        }else{
          this.utilService.showAlert('No se encontraron registros','Advertencia','warning')
        } 

      }else{
        this.utilService.showAlert(resp.message);

      }

    },error=>{
      console.log('error',error);
    })
  
  }

  
  generarPDF(){
    
    let pdf = new jsPDF('p','pt','letter');
    const fechaCorte = this.getFechaCorte();
    const fecha = fechaCorte.format('YYYY-MM');
    const title = `ESTADO DE CUENTA   ${fecha}`;
    
    pdf.setFontSize(14);
    pdf.text(title, 180, 50);
    
    pdf.setFontSize(12);
    pdf.text('Fecha', 30, 80);
    pdf.text('Descripción', 150, 80);
    pdf.text('Valor', 350, 80);
    pdf.text('Saldo', 500, 80);
    pdf.line(10,85,550,85)
    
    let saltoLinea:number = 20; 
    let iniY = 85;
    
    for (const key in this.data) {
      let posY = iniY + saltoLinea;
      pdf.text(this.data[key].movimiento_fecha, 20, posY );
      pdf.text(this.data[key].movimiento_descripcion, 130, posY );
      pdf.text(this.data[key].movimiento_valor, 400, posY, {align:'right'} );
      pdf.text(this.data[key].movimiento_saldo, 550, posY , {align:'right'} );
      iniY = posY;
    }
   
    pdf.save("extracto.pdf") 
  } 


  getFechaCorte(){
    const fechaCorte =  moment( this.formExtracto.get('fechaCorte')?.value );
    return  fechaCorte;
  }

}