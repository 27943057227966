<app-header title="PQRS" />
<div class="m-div-container">

    <div class="formulario">

        <div class="m-div-container">

            <div class="m-div-form">
                <h2>Completa los campos requeridos</h2>

                <form [formGroup]="pqrForm" (ngSubmit)="onSubmit()" class="m-fl">

                    <mat-form-field>
                        <mat-label>Tipo de Solicitud</mat-label>
                        <mat-select formControlName="pqr_tipo">
                            <mat-option *ngFor="let tipoPqr of tiposPqr" [value]="tipoPqr.tipdet_codigo">
                                {{ tipoPqr.tipdet_nombre }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="pqrForm.get('pqr_tipo')?.hasError('required')">Este campo es requerido</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field>
                        <mat-label>Observación</mat-label>
                        <textarea matInput formControlName="pqr_mensaje"></textarea>
                        <mat-error *ngIf="pqrForm.get('pqr_mensaje')?.hasError('required')">Este campo es requerido</mat-error>
                    </mat-form-field>
                    
                    <br>
                    <section>
                        @if( loading ){
                        <div class="m-loading">
                            <app-loading [tamano]="35" />
                        </div>
                        }@else{
                            <button mat-raised-button color="primary" type="submit" [disabled]="pqrForm.invalid">Enviar</button>
                        }
                    </section>
        
                </form>

            </div>
            
            <div class="m-div-container" *ngIf="pqrs.length > 0">
                <h2>Tus tickets anteriores:</h2>
                <div class="card-container">
                    <mat-card *ngFor="let pqr of pqrs">
                    <mat-card-content>
                        <p>Fecha: {{ pqr.pqr_fecha_creacion | date:'dd/MM/yyyy HH:mm:ss' }}</p>
                        <p>Tipo de Solicitud: {{ pqr.pqr_tipo }}</p>
                        <p>Observación: {{ pqr.pqr_mensaje }}</p>
                        <p>Estado: {{ pqr.pqr_estado }}</p>
                        <button mat-raised-button color="primary" *ngIf="pqr.mensajes != null && pqr.mensajes.length > 0" (click)="verMensajes(pqr)">Ver mensajes</button>
                    </mat-card-content>
                </mat-card>
                </div>
                
                <button mat-raised-button color="primary" *ngIf="paginaActual > 1" (click)="anterior()">Anterior</button>
                <button mat-raised-button color="primary" *ngIf="paginaActual < paginaMaxima" (click)="siguiente()">Siguiente</button>
            </div>

        </div>

    </div>
</div>
