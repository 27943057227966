import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolicitudRetirosService } from '../../services/solicitud-retiros/solicitud-retiros.service';
import { ISolicitudRetiros } from '../../models/solicitud-retiros/solicitud-retiros';
import { MatCardModule } from '@angular/material/card';
import { HeaderComponent } from '@app/shared/components/header/header.component';

@Component({
  selector: 'app-solicitud-retiros',
  standalone: true,
  imports: [CommonModule, MatCardModule, HeaderComponent],
  templateUrl: './solicitud-retiros.component.html',
  styleUrl: './solicitud-retiros.component.css'
})
export class getSolicitudRetirosComponent implements OnInit {

  solicitudesRetirosList: any[] = [];
  constructor(private soliciturRetirosService: SolicitudRetirosService){

  }

  ngOnInit(): void {
    this.getSolicitudRetiros();
  }

  getSolicitudRetiros() {
    this.soliciturRetirosService.getSolicitudRetiros().subscribe(
      (resp: ISolicitudRetiros) => {
        console.log(resp);
        if (resp.response.length > 0) {
          
          this.solicitudesRetirosList = resp.response.map((objeto: any) => {
            const fechaOriginal = new Date(objeto.dissol_fecha_creacion);
            fechaOriginal.setHours(fechaOriginal.getHours() + 5);
          
            return {
              ...objeto,
              dissol_estado_transaccion:
                objeto.dissol_estado_transaccion === 'A' ? 'APLICADA' :
                objeto.dissol_estado_transaccion === 'R' ? 'RECHAZADA' :
                'PENDIENTE',
              dissol_fecha_creacion: fechaOriginal.toLocaleString()
            };
          });

          
        }

      },
      (error) => {
        // Manejar errores aquí
        console.error('Error al obtener los tickets:', error);
      }
    );
  }
}
