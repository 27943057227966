<div class="m-div-reg-cuenta">
    <mat-card>
        <mat-card-header>
            <h1>Registar cuenta</h1>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="formRegCuenta" (submit)="handlerOnSubmit()">
                
                <section>
                    <mat-form-field>
                        <mat-label>Tipo documento:</mat-label>
                        <mat-select formControlName="tipoDocumento">
                            @for (tipoIdenti of tiposIdentificacion; track tipoIdenti.tipdet_codigo) {
                                <mat-option value="{{tipoIdenti.tipdet_codigo}}">{{tipoIdenti.tipdet_nombre}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Número documento:</mat-label>
                        <input matInput formControlName="numId" #doc>
                        @if( formRegCuenta.get('numId')?.invalid && formRegCuenta.get('numId')?.touched ){
                            <small>El número es requerido</small>
                        }
                    </mat-form-field>
                </section>

                <section>
                    <mat-form-field>
                        <mat-label>Nombre quien recibe:</mat-label>
                        <input matInput formControlName="nomRecibe">
                        @if( formRegCuenta.get('nomRecibe')?.invalid && formRegCuenta.get('nomRecibe')?.touched ){
                            <small>El Nombre es requerido</small>
                        }
                    </mat-form-field>
 
                    <mat-form-field>
                        <mat-label>Banco:</mat-label>
                        <mat-select formControlName="banco">
                        @for (banco of bancos; track banco.achban_codigo) {
                                <mat-option value="{{banco.achban_codigo}}">{{banco.achban_nombre}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                </section>

                <section>
                    <mat-form-field>
                        <mat-label>Tipo cuenta:</mat-label>
                        <mat-select formControlName="tipoCuenta">
                            @for (tipoCuenta of tipoCuentasBanco; track tipoCuenta.tipdet_codigo) {
                                <mat-option value="{{tipoCuenta.tipdet_codigo}}">{{tipoCuenta.tipdet_nombre}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Número cuenta:</mat-label>
                        <input matInput formControlName="numCuenta" type="number">
                        @if( formRegCuenta.get('numCuenta')?.invalid && formRegCuenta.get('numCuenta')?.touched ){
                            <small>La cuenta es requerida</small>
                        }
                    </mat-form-field>
                </section>
                <mat-card-actions>
                    @if(loading){
                        <app-loading [tamano]= "25"/>
                    }@else{
                        <button mat-flat-button type="submit" [disabled]="formRegCuenta.invalid" ><mat-icon>save</mat-icon></button>
                        <button mat-flat-button (click)="handlerClose()"><mat-icon>close</mat-icon></button>
                    }
                </mat-card-actions>

            </form>
        </mat-card-content>
    </mat-card>
</div>