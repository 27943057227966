import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UtilsService } from '@app/shared/services/utils.service';
import { InscripcionCuentaService } from '@app/modules/home/services/inscripcion-cuenta-banco/inscripcion-cuenta.service';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { ICreateCuenta } from '@app/modules/home/models/inscripcion-cuenta-banco/inscripcion-cuenta-interface';

@Component({
  selector: 'app-registrar-cuenta',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule, 
            MatCardModule, MatButtonModule, MatIconModule, LoadingComponent],
  templateUrl: './registrar-cuenta.component.html',
  styleUrl: './registrar-cuenta.component.css'
})
export class RegistrarCuentaComponent implements OnInit, AfterViewInit {

  bancos:any = [];
  tipoCuentasBanco: any = [];
  tiposIdentificacion: any = [];
  loading = false;
  cuentaCreada = false;
  formRegCuenta: FormGroup;

  @ViewChild('doc',{ static: true }) doc!: ElementRef;

  @Output() registarCuenta = new EventEmitter<FormGroup>();
  @Output() hiddeRegistrar = new EventEmitter<number>();

  constructor( private fb: FormBuilder, private utilService: UtilsService, private inscripcionCuentaService: InscripcionCuentaService, private renderer: Renderer2 ){

    this.formRegCuenta = this.fb.group({
      tipoDocumento:  ['',[Validators.required]],
      numId:          ['',[Validators.required]],
      nomRecibe:      ['',[Validators.required]],
      banco:          ['',[Validators.required]],
      tipoCuenta:     ['',[Validators.required]],
      numCuenta:      ['',[Validators.required]],
    });

  }
  
  ngAfterViewInit(): void {
    
    const micontrol =  this.renderer.selectRootElement(this.doc.nativeElement);
    
    if(micontrol){
      setTimeout(() => {
         micontrol.focus();
      }, 500);
     
    }
  }

  ngOnInit(): void {
    this.getBancos();
    this.getTipoCuentaBanco();
    this.getTiposIdentificacion();
  }

  
  handlerOnSubmit(){
    this.registarCuenta.emit(this.formRegCuenta);
    this.loading = true;

    const payload = {
                      achban_codigo: this.formRegCuenta.get('banco')!.value, 
                      usubancue_tipo_cuenta: this.formRegCuenta.get('tipoCuenta')!.value,  
                      usubancue_numero_cuenta: String(this.formRegCuenta.get('numCuenta')!.value),  
                      usubancue_tipo_identificacion: this.formRegCuenta.get('tipoDocumento')!.value, 
                      usubancue_identificacion: this.formRegCuenta.get('numId')!.value,
                      usubancue_nombre_completo: this.formRegCuenta.get('nomRecibe')!.value 
                    };

    this.inscripcionCuentaService.createCuentaBanco(payload).subscribe((resp: ICreateCuenta)=>{
      this.loading = false;
      
      if(resp.state){
        this.formRegCuenta.reset();
        this.cuentaCreada = true;
        this.utilService.showAlert(resp.message,'Exito!','success');
      }else{
        this.loading = false;
        this.utilService.showAlert(resp.message);
      }

    },(error: any)=>{
      this.loading = false;
      console.log('Error',error);
    });
  }

  
  handlerClose(){
    if( this.cuentaCreada ){
      this.hiddeRegistrar.emit(1);
    }else{
      this.hiddeRegistrar.emit(0);
    }
  }

  
  getBancos(){
    this.bancos = this.utilService.getAchBancos();
  }

  
  getTipoCuentaBanco(){
    this.tipoCuentasBanco = this.utilService.getTipoCuentaBanco();
  }

  
  getTiposIdentificacion(){
    this.tiposIdentificacion = this.utilService.getTiposIdentificacion();
  }

}