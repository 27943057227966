import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, firstValueFrom} from 'rxjs';
import { IComercioConceptosNuevo } from '@home/models/tienda/tienda-interface';
import { IConceptoValor, IReferenciaWompi } from '@app/modules/home/models/tienda/transporte-interface';

@Injectable({
  providedIn: 'root'
})
export class TiendaService {

  URLBASE = environment.URLBASE;

  token = JSON.parse(localStorage.getItem("user-billetera")!).token; 
  
  constructor(private http: HttpClient) { }

  getComercioConceptos(payload: any):Observable<IComercioConceptosNuevo>{

    let url = `${this.URLBASE}/app/tienda/categoria/comercioconceptosnuevo`;
    return this.http.post<IComercioConceptosNuevo>(url, payload).pipe(resp=>resp);
  }

  getConceptoValor(payload: any):Observable<IConceptoValor>{
    
    const url = `${this.URLBASE}/app/tienda/categoria/${payload.tiecat_codigo}/comercio/${payload.comercio_codigo}/concepto/${payload.concepto_codigo}/valor`;

    return this.http.post<IConceptoValor>(url, payload).pipe((resp)=>resp);

  }

  validarValorPago(payload:any){
    const url = `${this.URLBASE}/app/tienda/referencia/pago/validaValorCuenta`;
    return  firstValueFrom(
      this.http.post(url, payload)
    ) 
  }

  referenciaPago(payload:any){
    const url = `${this.URLBASE}/app/tienda/referencia/pago`;
    return firstValueFrom(
      this.http.post(url, payload)
    ); 

  }
  
  pagar(payload:any){
    const url = `${this.URLBASE}/app/tienda/pago`;
    return firstValueFrom(
      this.http.post(url, payload)
    ); 

  }

  referenciaPasarela(payload:any){
    const url = `${this.URLBASE}/app/tienda/referencia/pasarela`;
    return firstValueFrom(
      this.http.post<IReferenciaWompi>(url, payload)
    ); 
  }



}