<div class="m-div-container">

    <div class="m-div-form">
        <h1>Regístrate</h1>


        <form [formGroup]="formRegistro" (ngSubmit)="onSubmit()" class="m-fl">
            <section>
                <mat-form-field>
                    <mat-label>Tipo Identificación</mat-label>
                    <mat-select formControlName="tipoIdentificacion">
                        @for (tipo of tiposIdentificacion; track tipo) {
                        <mat-option value="{{tipo.tipdet_codigo}}">
                            {{tipo.tipdet_nombre}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Número Identificación:
                    </mat-label>
                    <input matInput type="number" formControlName="numId">

                    @if (formRegistro.get('numId')?.invalid && formRegistro.get('numId')?.touched) {
                    <small>El número no es válido</small>
                    }
                </mat-form-field>
            </section>

            <!-- NOMBRES -->
            <section>
                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Primer Nombre:
                    </mat-label>
                    <input matInput type="text" formControlName="nombre1">

                    @if (formRegistro.get('nombre1')?.invalid && formRegistro.get('nombre1')?.touched) {
                    <small>El nombre no es válido</small>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Segundo Nombre:
                    </mat-label>
                    <input matInput type="text" formControlName="nombre2">
                </mat-form-field>
            </section>

             <!-- APELLIDOS -->
            <section>
                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Primer Apellido:
                    </mat-label>
                    <input matInput type="text" formControlName="ape1">

                    @if (formRegistro.get('ape1')?.invalid && formRegistro.get('ape1')?.touched) {
                    <small>El apellido no es válido</small>
                    }
                </mat-form-field>

                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Segundo Apellido:
                    </mat-label>
                    <input matInput type="text" formControlName="ape2">
                </mat-form-field>
            </section>

             <!--EMPRESA Y CORREO -->
            <section>

                <mat-form-field>
                    <mat-label>Empresa</mat-label>
                    <mat-select formControlName="empresa">
                        @for (empresa of empresas; track empresa) {
                        <mat-option value="{{empresa.comercio_codigo}}">
                            {{empresa.comercio_nombre}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Correo Eletrónico:
                    </mat-label>
                    <input matInput type="email" formControlName="correo">

                    @if (formRegistro.get('correo')?.invalid && formRegistro.get('correo')?.touched) {
                    <small>El correo no es válido</small>
                    }
                </mat-form-field>

            </section>
            
            <!-- CLAVE -->
            <section>
                <mat-form-field>
                    <mat-label>
                        <mat-icon aria-hidden="false" aria-label="Example home icon"></mat-icon>
                        Clave:
                    </mat-label>
                    <input matInput type="number" formControlName="password" (keyup)="key()" style="text-align: center; font-size: larger;">

                    @if (formRegistro.get('password')?.invalid && formRegistro.get('password')?.touched) {
                    <small>La clave no es válida</small>
                    }
                </mat-form-field>
            </section>

            <!-- TERMINOS -->                
            <section>
                <mat-checkbox class="example-margin"
                    color="primary"
                    formControlName="terminos"
                >
                   <p> Acepto haber leído los
                        <span>términos y condiciones,
                              la política de privacidad
                        tratamiento de datos </span>
                        para realizar este registro.
                    </p>
                </mat-checkbox>
            </section>


            <section>
                @if( loading ){
                <div class="m-loading">
                    <app-loading [tamano]="35" />
                </div>
                }@else{
                <button mat-raised-button color="primary" type="submit"
                    [disabled]="formRegistro.invalid">Continuar</button>
                }
            </section>


        </form>
      
    </div>

</div>
