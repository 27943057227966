import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/modules/auth/services/login.service';
import { catchError, throwError } from 'rxjs';

export const autenticatedInterceptor: HttpInterceptorFn = (req, next) => {

  const loginService = inject(LoginService);
  const router = inject(Router);

  let token = '';
  let tokenTmp = loginService.tokenTmp;

  let headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  
  if ( localStorage.getItem("user-billetera") ){
    token = JSON.parse(localStorage.getItem("user-billetera")!).token; 
    headers = headers.append('Authorization', token);
    loginService.tokenTmp = '';
  
  }else{
    headers = headers.append('Authorization', tokenTmp);
  }

  const reqClone = req.clone({
    headers
  })
  
  return next(reqClone).pipe(catchError((error: HttpErrorResponse)=>{

    let errorMessage = {};

    if( error.status == 403){
      loginService.removeLocalStorage();
      router.navigate(['/login']);

      errorMessage = {
        "status": error.status,
        "message": "Su ha sesión expirado, por favor inicie sesión...." 
      };

      return throwError(errorMessage);
      
    }

    return throwError(error);

  }));
  
};