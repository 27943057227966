<mat-dialog-content>
    <h1>PQR - Mensajes</h1>
    <ul>
        <li>Fecha: {{ pqr.pqr_fecha_creacion | date:'dd/MM/yyyy HH:mm:ss' }}</li>
        <li>Tipo de Solicitud: {{ pqr.pqr_tipo }}</li>
        <li>Observación: {{ pqr.pqr_mensaje }}</li>
        <li>Estado: {{ pqr.pqr_estado }}</li>
    </ul>
    <h4>Historial de mensajes</h4>
    <mat-card *ngFor="let mensaje of pqr.mensajes">
        <mat-card-header>
            <mat-card-subtitle>{{mensaje.actor}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            {{mensaje.mensaje}}
        </mat-card-content>
    </mat-card>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true"><mat-icon>close</mat-icon> Cerrar</button>
</mat-dialog-actions>