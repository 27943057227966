import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatCardModule, MatButtonModule, MatIconModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.css'
})
export class ConfirmDialogComponent implements OnInit {


  titleBtnCancel = 'Cancelar';
  titleBtnOk = 'Aceptar';
  titleModal = 'Advertencia !';
  titleCard = 'Está seguro?';
  subTitleCard = '';
  contentCard = '';

  constructor( @Inject(MAT_DIALOG_DATA) public data: any ){}
  
  ngOnInit(): void {
    this.setData()
  }

  setData(){
    if( this.data.contentCard ){
      this.contentCard = this.data.contentCard;
    }
    
    if( this.data.titleBtnCancel ){
      this.titleBtnCancel = this.data.titleBtnCancel;
    }

    if(this.data.titleModal){
      this.titleModal = this.data.titleModal;
    }

    if(this.data.titleCard){
      this.titleCard = this.data.titleCard;
    }
  }

}
